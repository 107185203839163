import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
    @Input() state: boolean;
    @Output() changed: EventEmitter<boolean>;

    constructor() {
        this.changed = new EventEmitter<boolean>();
    }

    public onStateChanged(): void {
        this.state = !this.state;
        this.changed.next(this.state);
    }

}
