// Core modules
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, merge, Observable, of, Subscription} from 'rxjs';

// Third-party modules
import {filter, mapTo} from 'rxjs/operators';

// Internal interfaces
import {MessageInterface} from '@app/core/messaging/message';
import {Presence} from '@app/core/messaging/presence';
import {XMPPmessage} from '@app/core/messaging-provider/abstract-message-provider';
import {MessagingConnectionStatus, MessagingService} from '@app/core/messaging/messaging.service';
import {RemoteRelatedFile, RemoteRelatedFileAction} from '@app/core/messaging/remote-related-file';

// Internal models
import {Session} from '@app/shared/models/session';
import {SessionRoomStatus} from '@app/home/session/session-room-status';

// Internal services
import {I18nService} from '@app/core/i18n.service';
import {CustoService} from '@app/shared/service/custo.service';
import {AuthenticationService, ErrorMessage} from '@app/core/authentication/authentication.service';
import {HeaderService} from '@app/shell/header/header.service';
import {UtilService} from '@app/shared/service/util.service';
import {FlashService} from '@app/shared/flash/flash.service';
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {RelatedService} from '@app/shared/service/related.service';
import {SessionService} from '@app/shared/service/session.service';
import {Logger} from '@app/core/logger.service';

// Global variables declaration
const logger = new Logger('Home');

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

    /**
     * Data members
     */
    public showPresentation = false;
    public selectedSession: Session = null;
    public connectedToMessagingService = false;
    public isExternal = false;
    public errorConnectionToMessagingService = false;
    public nbLostConnexion: number = XMPPmessage.MAX_RETRY_CONNEXION;
    public online: Observable<boolean>;
    public isSharedRelatedOpened: boolean;
    public sharedRelatedUrl: string;
    private _roomStatus: SessionRoomStatus = SessionRoomStatus.Disconnected;
    private _messagingServiceStatusSubscription: Subscription;
    private _sharedRelatedOpeningSubscription: Subscription;
    private _initConnection = false;
    private _presences: any = {count: 0};
    private subscriptions: Subscription[] = [];
    private _isAlreadyConnected = false;

    /**
     * @function constructor
     * @param {UtilService} _utilService
     * @param {FlashService} flashService
     * @param {AuthenticationService} _authenticationService
     * @param {SessionService} _sessionService
     * @param {AuthenticationService} authService
     * @param {HeaderService} headerService
     * @param {MessagingService} messagingService
     * @param {OpentokService} _opentokService
     * @param {RelatedService} _relatedService
     * @param {I18nService} _i18nService
     * @param {CustoService} _custoService
     */
    constructor(
        private _utilService: UtilService,
        private flashService: FlashService,
        private _authenticationService: AuthenticationService,
        private _sessionService: SessionService,
        private authService: AuthenticationService,
        private headerService: HeaderService,
        private messagingService: MessagingService,
        private _opentokService: OpentokService,
        private _relatedService: RelatedService,
        private _i18nService: I18nService,
        private _custoService: CustoService
    ) {
        this._blockLeftRightSwipingChangePage();

        this._sessionService.getSessions()
            .subscribe(
                (result: Session[]) => {
                    // Auto-selecting the first (and sole) session if the user is an external
                    this._authenticationService.isExternalUser().then((bool: boolean) => {
                        this.isExternal = bool;
                        if (bool) {
                            // case when external user try to log to an deleted session
                            if (result.length === 0) {
                                this._utilService._doLogout();
                                return;
                            }
                            this.selectedSession = result[0];
                            return;
                        }
                    });
                });

        this.subscriptions.push(
            this.messagingService.Messages
                .pipe(filter(message => message instanceof RemoteRelatedFile))
                .subscribe((message: MessageInterface) => {
                    const remoteFile = <RemoteRelatedFile>message;
                    if (remoteFile.action === RemoteRelatedFileAction.CLOSE) {
                        // close shared related notification
                        this.isSharedRelatedOpened = false;
                    }
                })
        );
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this._initParams();
        this.headerService.changeVisibilty(true);
        this._messagingServiceStatusSubscription = this.messagingService.connectionStatusChanged
            .subscribe((data: MessagingConnectionStatus) => {
                    this.connectedToMessagingService = data.connected;
                    this._onMessagingServiceStatusChanged(data);
                }
            );
        this._sharedRelatedOpeningSubscription = this._relatedService.sharedRelatedOpeningSubject
            .subscribe((url: string) => {
                    this.sharedRelatedUrl = url;
                    this._onSharedRelatedOpening();
                }
            );
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
        this.messagingService.connect(this.authService.credentials.username);
        this._checkDualLoginAndLogout();
        setTimeout(() => {
            if (!this._isAlreadyConnected) {
                this._initNetworkStatus();
            }
        }, 5000);
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this._messagingServiceStatusSubscription.unsubscribe();
        this.messagingService.disconnect();
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * @function _initParams
     * @description
     * @private
     * @returns {void}
     */
    private _initParams(): void {
        this._custoService.paramsLoadedSubject.subscribe(() => {
            this._i18nService.setAvailableLocales = this._custoService.getParam('availableLocales');
            this._i18nService.language = null;
        });
    }

    /**
     * @function roomStatusChanged
     * @description
     * @public
     * @param {SessionRoomStatus} $event
     * @returns {void}
     */
    public roomStatusChanged($event: SessionRoomStatus): void {
        this._roomStatus = $event;
        if (this._roomStatus === SessionRoomStatus.Disconnected) {
            this.selectedSession = null;
            this.headerService.changeVisibilty(true);
        }
    }

    /**
     * @function openSharedRelated
     * @description
     * @public
     * @param {string} url
     * @returns {void}
     */
    public openSharedRelated(url: string): void {
        this._relatedService.openInNewTab(url);
        this.isSharedRelatedOpened = false;
    }

    /**
     * @function showHidePresentation
     * @description
     * @public
     * @param {boolean} $event
     * @returns {void}
     */
    public showHidePresentation($event: boolean): void {
        this.showPresentation = $event;
    }

    /**
     * @function forceReconnect
     * @description
     * @public
     * @returns {void}
     */
    public forceReconnect(): void {
        this.messagingService.connect(this.authService.credentials.username);
    }

    /**
     * @function _blockLeftRightSwipingChangePage
     * @description
     * @private
     * @returns {void}
     */
    private _blockLeftRightSwipingChangePage() {
        fromEvent(window, 'wheel', {passive: false}).subscribe((event: WheelEvent) => {
            let posX = 0;
            posX = posX - event.deltaX * 2;
            if (posX !== 0) {
                event.preventDefault();
            }
        });
    }

    /**
     * @function _checkDualLoginAndLogout
     * @description
     * @private
     * @returns {void}
     */
    private _checkDualLoginAndLogout() {
        this.messagingService.Messages.subscribe((message: MessageInterface) => {
            if (message.constructor === Presence) {
                if (message.from.indexOf('@conference.') === -1 &&
                    message.from.indexOf(this.authService.credentials.username) !== -1 &&
                    message.to === this.authService.credentials.connection_id
                ) {
                    if (this._presences[message.from] == null) {
                        this._presences[message.from] = Math.round((new Date()).getTime() / 1000);
                        this._presences.count = this._presences.count + 1;
                    }
                    if (this._presences.count > 1) {
                        let loop = 0;
                        let value1: any = null;
                        Object.keys(this._presences).forEach(index => {
                            const value = this._presences[index];
                            if (index !== 'count') {
                                loop++;
                                if (loop === 1) {
                                    value1 = value;
                                } else if (loop === 2) {
                                    const offset = value - value1;
                                    if (offset < 2) {
                                        if (!this._isAlreadyConnected) {
                                            this._utilService._doLogout('/login');
                                            this._presences = {count: 0};
                                            // disconnect user from xmpp
                                            this.messagingService.disconnect();
                                            this._opentokService.disable();
                                            setTimeout(() => {
                                                this.authService.errorSubject.next(ErrorMessage.DUAL_LOGIN);
                                            }, 1000);
                                        }
                                        this._isAlreadyConnected = true;
                                    }
                                }
                            }
                        });
                    }
                }
            }
        });
    }

    /**
     * @function _onMessagingServiceStatusChanged
     * @description
     * @private
     * @param {MessagingConnectionStatus} status
     * @returns {void}
     */
    private _onMessagingServiceStatusChanged(status: MessagingConnectionStatus): void {
        this.connectedToMessagingService = status.connected;
        if (!this.connectedToMessagingService) {
            this.errorConnectionToMessagingService = true;
        } else {
            this.errorConnectionToMessagingService = false;
        }

        // Handle disconnection with interface
        if (!this.connectedToMessagingService && this.selectedSession) {
            this.selectedSession = null;
            this.headerService.changeVisibilty(true);
        }
    }

    /**
     * @function _onSharedRelatedOpening
     * @description
     * @private
     * @returns {void}
     */
    private _onSharedRelatedOpening(): void {
        this.isSharedRelatedOpened = true;
    }

    /**
     * @function _resetConnection
     * @description
     * @private
     * @returns {void}
     */
    private _resetConnection(): void {
        this._initConnection = true;
        this.messagingService.resetConnection(() => {
            const room = 'r' + this.selectedSession.sessionKey + '@conference.' + this.authService.credentials.tenant;
            this.messagingService.joinChannel(room, this.selectedSession.members[0].memberDN);
            this._initConnection = false;
        });
    }

    /**
     * @function _initNetworkStatus
     * @description
     * @private
     * @returns {void}
     */
    private _initNetworkStatus() {
        this.online = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        );
        this.online.subscribe(isOnline => {
            // Resetting XMPP connection if navigator passed from offline to online
            // and XMPP connection state is still disconnected
            if (isOnline && !this.messagingService.isConnected()) {
                setTimeout(() => {
                    this._resetConnection();
                }, 5000);
            }
        });
    }
}
