// Core modules
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

// Third-party modules
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class VersionService {

    /**
     * Data members
     */
    private _version: string;

    /**
     * @function constructor
     * @param {HttpClient} http
     */
    constructor(
        private http: HttpClient
    ) {}

    /**
     * @function getVersion
     * @description
     * @public
     * @returns {Observable<string>}
     */
    public getVersion(): Observable<string> {
        if (this._version) {
            return of(this._version);
        } else {
            return this.http.get('assets/version.txt?t=' + new Date().getTime(), {responseType: 'text'})
                .pipe(
                    map((body: string) => body)
                );
        }
    }

    /**
     * @function setVersion
     * @description
     * @public
     * @param {string} version
     * @returns {void}
     */
    public setVersion(version: string) {
        this._version = version;
    }

}
