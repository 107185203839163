import {Component, OnDestroy, OnInit} from '@angular/core';
import {FlashService} from '@app/shared/flash/flash.service';
import {Subscription} from 'rxjs';
import {Flash} from '@app/shared/flash/flash';
import {Logger} from '@app/core/logger.service';
const logger = new Logger('App');


@Component({
  selector: 'app-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss']
})
export class FlashComponent implements OnInit, OnDestroy {

  public flashes: Flash[] = [];
  private flashSubscription: Subscription;

  constructor(private flashService: FlashService) {
    this.flashSubscription = this.flashService.flashes.subscribe(
        (flash: Flash) => {
          this.flashes.push(flash);
          setTimeout(() =>  {
            this.close(flash);
          }, flash.duration);
        }, (err) => {
          logger.error(err);
        }
    );
  }

    /**
     * @function ngOnInit
     */
  ngOnInit() {}

    /**
     * @function ngOnDestroy
     */
  ngOnDestroy() {
      this.flashSubscription.unsubscribe();
  }

  public close(flash: Flash): void {
      this.flashes.splice(this.flashes.indexOf(flash), 1);
  }

}
