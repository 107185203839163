// Core modules
import {Injectable} from '@angular/core';

// Internal modules
import {AppConfig} from '@app/core/app.config';

// Internal interfaces
import {AbstractMessageProvider} from '@app/core/messaging-provider/abstract-message-provider';
import {Message, MessageInterface} from '@app/core/messaging/message';
import {LoadSequence, LoadSlide} from '@app/core/messaging/load-slide';
import {TouchGesture, TouchGestureType} from '@app/core/messaging/touch-gesture';
import {DrawAction, DrawActionType} from '@app/core/messaging/draw-action';
import {SurveyNotification} from '@app/core/messaging/survey-notification';
import {RevokeAccess} from '@app/core/messaging/revoke-access';
import {AllowStreaming} from '@app/core/messaging/allow-streaming';
import {ScreenSize} from '@app/core/messaging/screen-size';
import {CommentBox, CommentBoxActionType} from '@app/core/messaging/comment-box';
import {PresenterControl} from '@app/core/messaging/presenter-control';
import {InteractiveModeEnableCommand} from '@app/core/messaging/interactive-mode-enable-command';
import {VideoAction, VideoEventType} from '@app/core/messaging/video-action';
import {SessionStatus} from '@app/core/messaging/session-state';
import {SessionPlayPauseState} from '@app/core/messaging/session-play-pause-state';
import {RemoteRelatedFile, RemoteRelatedFileAction} from '@app/core/messaging/remote-related-file';
import {ConferenceEnableCommand} from '@app/core/messaging/conference-enable-command';
import {SlideLoadedEvent} from '@app/core/messaging/slide-loaded-event';
import {CurrentSlide} from '@app/core/messaging/current-slide';
import {HighlightingModeCommand} from '@app/core/messaging/highlighting-mode-command';
import {DrawingModeCommand} from '@app/core/messaging/drawing-mode-command';
import {DisallowStreaming} from '@app/core/messaging/disallow-streaming';
import {Notification} from '@app/core/messaging/notification';
import {ChatMessage} from '@app/core/messaging/chat-message';
import {Presence} from '@app/core/messaging/presence';
import {RoomConnectionStatus} from '@app/core/messaging/room-connection-status';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {Logger} from 'app/core/logger.service';
import {XmppRoomProvider} from '@app/core/messaging-provider/xmpp-room-provider.service';

// Global variables declaration
const logger = new Logger('XMPP Provider');

declare var Strophe: any;
declare var $pres: any;
declare var $msg: any;

@Injectable()
export class XmppProviderService extends AbstractMessageProvider {

    private static PRESENCE_CONNECTION_TIMEOUT = 5000;

    /**
     * Data members
     */
    public connection: any;
    public currentRoom: string;
    public messageReceivedCallback: (message: MessageInterface) => void;
    public connectedCallback: () => void;
    public disconnectedCallback: () => void;
    public joinRoomCallBack: () => void;
    readonly _browserInfo: any;
    private _isResetConnection = false;
    private OCE_CONTENTS_PROXY = '/contentoce/';
    private LOCAL_ENV_NAME = 'local';

    /**
     * @function constructor
     * @param {AppConfig} config
     * @param {AuthenticationService} authService
     * @param {BrowserService} _browserService
     * @param {XmppRoomProvider} xmppRoomProvider
     */
    constructor(
        private config: AppConfig,
        private authService: AuthenticationService,
        private _browserService: BrowserService,
        private xmppRoomProvider: XmppRoomProvider
    ) {
        super();
        this._browserInfo = this._getBrowserInfo();
    }

    /**
     * @function connect
     * @description Connect to Strophe
     * @public
     * @returns {void}
     */
    public connect(): void {
        if (this.authService.credentials) {
            if (!this.connection) {
                this.connection = new Strophe.Connection(this.config.get('webSocketUrl'));
                this.connection.connect(this.authService.credentials.connection_id, this.authService.credentials.access_token, this.onXmppConnected.bind(this));
            } else {
                this.connectedCallback();
            }
        }
    }

    /**
     * @function resetConnection
     * @description Reset Strophe connection
     * @public
     * @param {() => void} callBack
     * @returns {void}
     */
    public resetConnection(callBack: () => void): void {
        if (this.connection && !this.connection.connected) {
            console.log('Resetting XMPP connection');
            this.disconnect();
            this.connect();
            if (callBack) {
                this.joinRoomCallBack = callBack;
                this._isResetConnection = true;
            }
        }
    }

    /**
     * @function disconnect
     * @description Disconnect from Strophe
     * @public
     * @returns {void}
     */
    public disconnect(): void {
        if (this.connection) {
            this.connection.disconnect();
            // this.connection.flush();
            this.connection = null;
        }
    }

    /**
     * @function isConnection
     * @description Check if Strophe connection is performed
     * @public
     * @returns {boolean}
     */
    public isConnection(): boolean {
        return this.connection;
    }

    /**
     * @function isConnected
     * @description Check if Strophe is connected
     * @public
     * @param {() => void} callBack
     * @returns {void}
     */
    public isConnected(): boolean {
        return this.connection && this.connection.connected;
    }

    /**
     * @function onConnected
     * @description
     * @public
     * @param {() => void} callBack
     * @returns {void}
     */
    public onConnected(callback: () => void) {
        this.connectedCallback = callback;
    }

    /**
     * @function onDisconnect
     * @description
     * @public
     * @param {() => void} callBack
     * @returns {void}
     */
    public onDisconnect(callback: () => void) {
        this.disconnectedCallback = callback;
    }

    /**
     * @function sendMessage
     * @description
     * @public
     * @param {Message} message
     * @returns {boolean}
     */
    public sendMessage(message: Message): void {
        if (!this.authService.credentials) {
            return;
        }

        if (!message.from) {
            message.from = this.authService.credentials.connection_id;
        }

        if (!message.to && this.currentRoom) {
            message.to = this.currentRoom;
        }

        const msg = $msg({to: message.to, from: message.from, type: message.type});

        if (message.body) {
            msg.c('body').t(message.body).up();
        }

        if (this.connection.connected) {
            this.connection.send(msg);
        }
    }

    /**
     * @function sendPresence
     * @description
     * @public
     * @deprecated
     * @param {string} to
     * @param {string} type
     * @returns {boolean}
     */
    public sendPresence(to: string, type: string): boolean {
        const msg = $pres({to: to, type: type});
        this.connection.send(msg);
        return true;
    }

    /**
     * @function _createXmppMsg
     * @description
     * @private
     * @returns {any}
     */
    private _createXmppMsg(): any {
        const msg: any = {};
        msg.ie = {};
        msg.ie.t = new Date().getTime() / 1000;
        msg.ie.u = this.authService.credentials.username;
        return msg;
    }

    /**
     * @function sendDrawingAction
     * @description
     * @public
     * @param {any} drawMessage
     * @returns {void}
     */
    public sendDrawingAction(drawMessage: any): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = this._createXmppMsg();
        msg.ie.a = drawMessage.data.actionType;
        msg.ie.p = drawMessage.data.color;
        msg.ie.x = drawMessage.data.x;
        msg.ie.y = drawMessage.data.y;
        msg.ie.platform = this._browserInfo.platform;
        msg.ie.browser = this._browserInfo.browser;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function sendEraseDrawingAction
     * @description
     * @public
     * @returns {void}
     */
    public sendEraseDrawingAction(): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = this._createXmppMsg();
        msg.ie.a = 3;
        msg.ie.oy = 0;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function requestAudioVideoStreaming
     * @description
     * @public
     * @param {string} speakerUid
     * @returns {void}
     */
    public requestAudioVideoStreaming(speakerUid: string): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = this._createXmppMsg();
        msg.ie.a = 13;
        msg.ie.u = speakerUid;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function askForHand
     * @description
     * @public
     * @param {string} participantUID
     * @returns {void}
     */
    public askForHand(participantUID: string): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = this._createXmppMsg();
        msg.ie.a = 18;
        msg.ie.u = participantUID;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function mandatoryFileNotification
     * @description
     * @public
     * @param {string} mandatoryFileName
     * @param {string} status
     * @returns {void}
     */
    public mandatoryFileNotification(mandatoryFileName: string, status: string): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = {};
        msg.MandatoryDocumentViewingStatus = {};
        msg.MandatoryDocumentViewingStatus.name = mandatoryFileName;
        msg.MandatoryDocumentViewingStatus.status = status;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function closeLinkPreview
     * @description Send a message to SDK to close link preview modal window
     * @public
     * @returns {void}
     */
    public closeLinkPreview(): void {
        const message = new Message();
        message.type = 'groupchat';
        message['action'] = 'CLOSE';
        const msg: any = {};
        msg.RelatedDocumentCloseCommand = {};
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function leaveHand
     * @description
     * @public
     * @param {string} presenterUID
     * @returns {void}
     */
    public leaveHand(presenterUID: string): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = this._createXmppMsg();
        msg.ie.a = 2;
        msg.ie.u = presenterUID;
        msg.ie.p = presenterUID;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function sendTouch
     * @description
     * @public
     * @param {string} eventType
     * @param {number} currentPositionX
     * @param {number} currentPositionY
     * @param {string} JID
     * @returns {void}
     */
    public sendTouch(eventType: string, currentPositionX: number, currentPositionY: number, JID: string): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = this._createXmppMsg();
        msg.ie.a = 1;
        msg.ie.p = eventType;
        msg.ie.u = JID;
        msg.ie.oy = 0;
        msg.ie.y = currentPositionY;
        msg.ie.x = currentPositionX;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function sendVideoAction
     * @description
     * @public
     * @param {string} eventType
     * @param {number} index
     * @param {number} currentTime
     * @param {string} JID
     * @returns {void}
     */
    public sendVideoAction(eventType: string, index: number, currentTime: number, JID: string): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = this._createXmppMsg();
        msg.ie.a = 10;
        msg.ie.u = JID;
        msg.ie.p = {};
        msg.ie.p.i = index;
        msg.ie.p.e = eventType;
        msg.ie.p.ct = currentTime;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function slideLoadedNotification
     * @description
     * @public
     * @param {SlideLoadedEvent} slideEvent
     * @returns {void}
     */
    public slideLoadedNotification(slideEvent: SlideLoadedEvent): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = {};
        msg.PresentationSlideLoadStatus = slideEvent;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function presentationSlideLoadCommand
     * @description
     * @public
     * @param {CurrentSlide} slide
     * @returns {void}
     */
    public presentationSlideLoadCommand(slide: CurrentSlide): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = {};
        msg.PresentationSlideLoadCommand = slide;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function askForCurrentSlide
     * @description
     * @public
     * @returns {void}
     */
    public askForCurrentSlide(): void {
        const message = new Message();
        message.type = 'groupchat';
        const msg: any = this._createXmppMsg();
        msg.ie.a = 11;
        message.body = JSON.stringify(msg);
        this.sendMessage(message);
    }

    /**
     * @function presentationSlideLoadCommand
     * @description
     * @public
     * @param {(message: Message) => void} callback
     * @returns {void}
     */
    public onMessageReceived(callback: (message: Message) => void): void {
        this.messageReceivedCallback = callback;
    }

    /**
     * @function joinRoom
     * @description
     * @public
     * @param {string} room
     * @returns {void}
     */
    public joinRoom(room: string): void {
        this.currentRoom = room;
        if (this.connection && this.connection.muc) {
            this.connection.muc.join(
                room,
                this.authService.credentials.username,
                this.onRoomMsgReceive.bind(this),
                null,
                null,
                this.authService.credentials.access_token
            );
        }
    }

    /**
     * @function leaveRoom
     * @description
     * @public
     * @param {string} room
     * @returns {void}
     */
    public leaveRoom(room: string): void {
        logger.info('Leaving room : ' + room);
        if (this.connection && this.connection.muc) {
            this.connection.muc.leave(
                room,
                this.authService.credentials.username, () => { console.log('leaved room : ' + room); }
            );
        }
    }

    /**
     * @function onXmppConnected
     * @description
     * @public
     * @param {any} status
     * @returns {void}
     */
    public onXmppConnected(status: any) {
        if (status === Strophe.Status.CONNFAIL) {
            // Connection failed
            logger.info('Connection failed');
        } else if (status === Strophe.Status.DISCONNECTED) {
            // Disconnected status
            logger.info('Disconnected');
        } else if (status === Strophe.Status.AUTHFAIL) {
            // Authenticating failed
            logger.info('Auth Failed');
        } else if (status === Strophe.Status.CONNECTED) {
            // Connected status
            logger.info('Connected');
            this.connection.addHandler(this.onPubSubCBEvent.bind(this), null, 'presence');

            // send presence message to the room
            // ignore 'successful' callback
            this.connection.sendPresence($pres(), null, (err: any) => {
                // inform SessionComponent that presence in the room was not confirmed
                logger.error(['Not connected to ejabberd room', err]);
                const message = this.stanzaToRoomConnectionStatus($msg({reason: err || 'timeout'}).tree(), false);
                this.messageReceivedCallback(message);
            }, XmppProviderService.PRESENCE_CONNECTION_TIMEOUT);

            if (this._isResetConnection) {
                this.joinRoomCallBack();
                this._isResetConnection = false;
                return true;
            }

            this.connectedCallback();
            return true;
        } else {
            // Other status
            logger.info(status);
        }
    }

    /**
     * @function onRoomMsgReceive
     * @description
     * @public
     * @param {any} stanza
     * @returns {boolean}
     */
    public onRoomMsgReceive(stanza: any): boolean {
        this.onXmppMsgReceive(stanza);
        return true;
    }

    /**
     * @function onPubSubCBEvent
     * @description
     * @public
     * @param {any} stanza
     * @returns {boolean}
     */
    public onPubSubCBEvent(stanza: any): boolean {
        this.onXmppMsgReceive(stanza);
        return true;
    }

    /**
     * @function formatStanza
     * @description
     * @public
     * @param {Element} stanza
     * @returns {MessageInterface}
     */
    public formatStanza(stanza: Element): MessageInterface {
        if (stanza.tagName === 'presence') {
            const status = stanza.getElementsByTagName('status');

            // check if room did not exist before, and register it on server if did not
            if (this.xmppRoomProvider.checkForNewRoom(status)) {
                this.xmppRoomProvider.stanzaToUnlockRoom(this.connection, this.currentRoom);
                return;
            }

            // Checking if access has to be revoked
            for (let i = 0; i < status.length; i++) {
                if (status[i].getAttribute('code') === '307') {
                    return this.stanzaToRevokeAccess(stanza);
                }
            }
            return this.stanzaToPresence(stanza);
        }

        if (stanza.getElementsByTagName('notification').length > 0) {
            const notification = stanza.getElementsByTagName('notification');
            const survey = JSON.parse(notification[0].textContent);

            if (survey.notify.survey) {
                return this.stanzaToSurveyNotification(stanza);
            } else {
                return this.stanzaToNotification(stanza);
            }
        }

        const body = stanza.getElementsByTagName('body');
        if (body.length > 0) {
            const content = JSON.parse(body[0].textContent);

            if (content.ChatMessage) {
                return this.stanzaToChatMessage(stanza);
            }

            if (typeof content.PresentationSequenceLoadCommand !== 'undefined') {
                return this.stanzaToLoadSequence(stanza);
            }

            if (typeof content.PresentationSlideLoadCommand !== 'undefined') {
                return this.stanzaToLoadSlide(stanza);
            }

            if (typeof content.SessionStateChangeCommand !== 'undefined') {
                return this.stanzaToSessionPlayPauseState(stanza);
            }

            if (typeof content.SessionStatus !== 'undefined') {
                return this.stanzaToSessionStatus(stanza);
            }

            if (typeof content.RelatedDocumentOpenCommand !== 'undefined'
                || typeof content.RelatedDocumentCloseCommand !== 'undefined') {
                return this.stanzaToRemoteRelatedDocument(stanza);
            }

            if (typeof content.ConferenceChangeCommand !== 'undefined') {
                return this.stanzaToConferenceEnableCommand(stanza);
            }

            if (typeof content.InteractiveModeCommand !== 'undefined') {
                return this.stanzaToInteractiveModeCommand(stanza);
            }

            if (typeof content.HighlightingModeCommand !== 'undefined') {
                return this.stanzaToHighlightingModeCommand(stanza);
            }

            if (typeof content.DrawingModeCommand !== 'undefined') {
                return this.stanzaToDrawingModeCommand(stanza);
            }

            if (typeof content.ie !== 'undefined' && typeof content.ie.a !== 'undefined') {
                const action = parseInt(content.ie.a, 10);
                switch (action) {
                    case 1:
                        return this.stanzaToTouch(stanza);

                    case 2:
                        let message = new PresenterControl();
                        message = this.fetchCommonMessageProperties(message, stanza);
                        if (message.to === this.authService.credentials.connection_id) {
                            return this._stanzaToPresenterControl(stanza);
                        }
                        break;

                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        return this.stanzaToDrawAction(stanza);

                    case 10:
                        return this.stanzaToVideoAction(stanza);

                    case 14:
                        return this.stanzaToAllowStreaming(stanza);

                    case 140:
                        return this.stanzaToDisallowStreaming(stanza);

                    case 20:
                        return this.stanzaToCommentBox(stanza, CommentBoxActionType.Create);

                    case 22:
                        return this.stanzaToCommentBox(stanza, CommentBoxActionType.Delete);
                }
            }
        }
        return this.stanzaToMessage(stanza);
    }

    /**
     * @function stanzaToRevokeAccess
     * @description
     * @private
     * @param {Element} stanza
     * @returns {RevokeAccess}
     */
    private stanzaToRevokeAccess(stanza: Element): RevokeAccess {
        const reason = stanza.getElementsByTagName('reason');
        const item = stanza.getElementsByTagName('item');
        let message = new RevokeAccess();
        message = this.fetchCommonMessageProperties(message, stanza);

        if (reason.length > 0) {
            message.reason = reason[0].textContent;
        }

        if (item.length > 0) {
            message.jidToRevoke = item[0].getAttribute('jid');
        }

        return message;
    }

    /**
     * @function stanzaToPresence
     * @description
     * @private
     * @param {Element} stanza
     * @returns {Presence}
     */
    private stanzaToPresence(stanza: Element): Presence {
        const item = stanza.getElementsByTagName('item');
        let message = new Presence();
        message = this.fetchCommonMessageProperties(message, stanza);

        if (item.length > 0) {
            message.user = {
                jid: item[0].getAttribute('jid'),
                role: item[0].getAttribute('role'),
                affiliation: item[0].getAttribute('affiliation'),
            };
            message.uid = Strophe.getResourceFromJid(message.from);
        }

        if (message.type === 'unavailable' && item.length === 0) {
            message.uid = Strophe.getBareJidFromJid(message.from);
        }

        return message;
    }

    /**
     * @function stanzaToRoomConnectionStatus
     * @description
     * @private
     * @param {Element} stanza
     * @param {boolean} connected
     * @returns {RoomConnectionStatus}
     */
    private stanzaToRoomConnectionStatus(stanza: Element, connected: boolean): RoomConnectionStatus {
        let message = new RoomConnectionStatus();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.reason = stanza.getAttribute('reason');
        message.status = connected ? 'connected' : 'disconnected';

        return message;
    }

    /**
     * @function stanzaToLoadSequence
     * @description
     * @private
     * @param {Element} stanza
     * @returns {LoadSequence}
     */
    private stanzaToLoadSequence(stanza: Element): LoadSequence {
        let message = new LoadSequence();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'load-slide';
        message.size = new ScreenSize();

        if (message.body.PresentationSequenceLoadCommand.sequenceIdentifier) {
            message.sequenceIdentifier = message.body.PresentationSequenceLoadCommand.sequenceIdentifier;
        }

        if (message.body.PresentationSequenceLoadCommand.presentationIdentifier) {
            message.presentationIdentifier = message.body.PresentationSequenceLoadCommand.presentationIdentifier;
        }

        if (message.body.PresentationSequenceLoadCommand.sender) {
            message.sender = message.body.PresentationSequenceLoadCommand.sender;
        }

        if (message.body.PresentationSequenceLoadCommand.dynamicContent) {
            message.dynamicContent = message.body.PresentationSequenceLoadCommand.dynamicContent;
        }

        if (message.body.PresentationSequenceLoadCommand.sequenceURL) {
            message.sequenceURL = message.body.PresentationSequenceLoadCommand.sequenceURL;
        }

        if (message.body.PresentationSequenceLoadCommand.timestamp) {
            message.timestamp = message.body.PresentationSequenceLoadCommand.timestamp;
        }

        if (message.body.PresentationSequenceLoadCommand.size &&
            message.body.PresentationSequenceLoadCommand.size.width &&
            message.body.PresentationSequenceLoadCommand.size.height) {
            message.size.height = message.body.PresentationSequenceLoadCommand.size.height;
            message.size.width = message.body.PresentationSequenceLoadCommand.size.width;
        }

        return message;
    }

    /**
     * @function stanzaToLoadSlide
     * @description
     * @private
     * @param {Element} stanza
     * @returns {LoadSlide}
     */
    private stanzaToLoadSlide(stanza: Element): LoadSlide {
        let message = new LoadSlide();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'load-slide';
        message.size = new ScreenSize();

        if (message.body.PresentationSlideLoadCommand.sequenceIdentifier) {
            message.sequenceIdentifier = message.body.PresentationSlideLoadCommand.sequenceIdentifier;
        }

        if (message.body.PresentationSlideLoadCommand.presentationIdentifier) {
            message.presentationIdentifier = message.body.PresentationSlideLoadCommand.presentationIdentifier;
        }

        if (message.body.PresentationSlideLoadCommand.sender) {
            message.sender = message.body.PresentationSlideLoadCommand.sender;
        }

        if (message.body.PresentationSlideLoadCommand.dynamicContent) {
            message.dynamicContent = message.body.PresentationSlideLoadCommand.dynamicContent;
        }

        if (message.body.PresentationSlideLoadCommand.slideURL) {
            message.slideURL = message.body.PresentationSlideLoadCommand.slideURL;
        }

        if (message.body.PresentationSlideLoadCommand.slideName) {
            message.slideName = message.body.PresentationSlideLoadCommand.slideName;
        }

        if (message.body.PresentationSlideLoadCommand.timestamp) {
            message.timestamp = message.body.PresentationSlideLoadCommand.timestamp;
        }

        if (message.body.PresentationSlideLoadCommand.size &&
            message.body.PresentationSlideLoadCommand.size.width &&
            message.body.PresentationSlideLoadCommand.size.height) {
            message.size.height = message.body.PresentationSlideLoadCommand.size.height;
            message.size.width = message.body.PresentationSlideLoadCommand.size.width;
        }

        return message;
    }

    /**
     * @function stanzaToSessionPlayPauseState
     * @description
     * @private
     * @param {Element} stanza
     * @returns {SessionPlayPauseState}
     */
    private stanzaToSessionPlayPauseState(stanza: Element): SessionPlayPauseState {
        let message = new SessionPlayPauseState();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'notification';

        if (message.body.SessionStateChangeCommand.state) {
            message.state = message.body.SessionStateChangeCommand.state;
        }

        if (message.body.SessionStateChangeCommand.currentSlide) {
            message.currentSlide = message.body.SessionStateChangeCommand.currentSlide;
            message.currentSlide.url = this._getContentUrl(message.currentSlide.slideURL);
        }

        return message;
    }

    /**
     * @function _getContentUrl
     * @description
     * @private
     * @param {string} url
     * @returns {string}
     */
    private _getContentUrl(url: string): string {
        if (this.config.get('env') === this.LOCAL_ENV_NAME && this.config.get('env') === 'local') {
            const urlBase = document.location.origin + this.OCE_CONTENTS_PROXY;
            let filePath = '';
            const urlTab = url && url.split('/');
            for (let i = 4, length = urlTab.length; i < length; i++) {
                filePath += urlTab[i] + '/';
            }
            return urlBase + filePath.substring(0, filePath.length - 1);
        } else {
            return url;
        }
    }

    /**
     * @function stanzaToAllowStreaming
     * @description
     * @private
     * @param {Element} stanza
     * @returns {AllowStreaming}
     */
    private stanzaToAllowStreaming(stanza: Element): AllowStreaming {
        let message = new AllowStreaming();
        message = this.fetchCommonMessageProperties(message, stanza);
        if (message.body.ie.p) {
            message.streamerUid = message.body.ie.p;
        }
        return message;
    }

    /**
     * @function stanzaToDisallowStreaming
     * @description
     * @private
     * @param {Element} stanza
     * @returns {DisallowStreaming}
     */
    private stanzaToDisallowStreaming(stanza: Element): DisallowStreaming {
        let message = new DisallowStreaming();
        message = this.fetchCommonMessageProperties(message, stanza);
        if (message.body.ie.p) {
            message.streamerUid = message.body.ie.p;
        }
        return message;
    }

    /**
     * @function stanzaToDrawAction
     * @description
     * @private
     * @param {Element} stanza
     * @returns {DrawAction}
     */
    private stanzaToDrawAction(stanza: Element): DrawAction {
        let message = new DrawAction();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'draw-action';
        message.x = message.body.ie.x;
        message.y = message.body.ie.y;
        message.userID = message.from;
        message.platform = message.body.ie.platform;
        // message.color = "#000"; // No color by default

        if (typeof message.body.ie.p !== 'undefined') {
            const num = parseInt(message.body.ie.p, 10);
            // tslint:disable-next-line:no-bitwise
            const unsigned = num >>> 0;
            message.color = '#' + unsigned.toString(16).substring(2);
        }

        const msgType = parseInt(message.body.ie.a, 10);
        switch (msgType) {
            case 3:
                message.actionType = DrawActionType.CLEAR;
                break;

            case 4:
                message.actionType = DrawActionType.STARTED;
                break;

            case 5:
                message.actionType = DrawActionType.ADD;
                break;

            case 6:
                message.actionType = DrawActionType.ENDED;
                break;

            case 7:
                message.actionType = DrawActionType.CANCELED;
                break;

            default:
                logger.error('Unknown draw action: ' + message.body.ie.a);
                break;
        }

        return message;
    }

    /**
     * @function stanzaToTouch
     * @description
     * @private
     * @param {Element} stanza
     * @returns {TouchGesture}
     */
    private stanzaToTouch(stanza: Element): TouchGesture {
        let message = new TouchGesture();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'touch-gesture';
        message.x = message.body.ie.x;
        message.y = message.body.ie.y;

        switch (message.body.ie.p) {
            case 's':
                message.gestureType = TouchGestureType.SCROLL;
                break;

            case 'm':
                message.gestureType = TouchGestureType.MOVE;
                break;

            case 'c':
                message.gestureType = TouchGestureType.CANCEL;
                break;

            case 'click':
                message.gestureType = TouchGestureType.CLICK;
                break;

            case 'd':
                message.gestureType = TouchGestureType.DOWN;
                break;

            case 'u':
                message.gestureType = TouchGestureType.UP;
                break;

            default:
                logger.error('Unknown gesture type:' + message.body.ie.p);
                break;
        }

        return message;
    }

    /**
     * @function stanzaToVideoAction
     * @description
     * @private
     * @param {Element} stanza
     * @returns {VideoAction}
     */
    private stanzaToVideoAction(stanza: Element): VideoAction {
        let message = new VideoAction();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'video-action';
        message.currentTime = message.body.ie.p.ct;
        message.index = message.body.ie.p.i;

        switch (message.body.ie.p.e) {
            case VideoEventType.PLAY:
                message.eventType = VideoEventType.PLAY;
                break;
            case VideoEventType.PAUSE:
                message.eventType = VideoEventType.PAUSE;
                break;
            default:
                logger.error('Unknown video type event:' + message.body.ie.p);
                break;
        }
        return message;
    }

    /**
     * @function stanzaToCommentBox
     * @description
     * @private
     * @param {Element} stanza
     * @param {actionType} CommentBoxActionType
     * @returns {CommentBox}
     */
    private stanzaToCommentBox(stanza: Element, actionType: CommentBoxActionType): CommentBox {
        let message = new CommentBox();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.body.ie.p = JSON.parse(message.body.ie.p);
        // message.contentId = message.body.ie.p;

        const commentBoxJson = message.body.ie.p.CommentBox;
        message.oid = commentBoxJson.oid;
        message.versionUrn = commentBoxJson.versionURN;
        message.anchor = commentBoxJson.anchor;
        message.owner = commentBoxJson.owner;
        message.style = commentBoxJson.style;
        message.height = commentBoxJson.height;
        message.width = commentBoxJson.width;
        message.x = commentBoxJson.x;
        message.y = commentBoxJson.y;
        message.text = commentBoxJson.text;
        message.actionType = actionType;

        return message;
    }

    /**
     * @function stanzaToChatMessage
     * @description
     * @private
     * @param {Element} stanza
     * @returns {ChatMessage}
     */
    private stanzaToChatMessage(stanza: Element): ChatMessage {
        let message = new ChatMessage();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.message = message.body.ChatMessage.content;
        message.timestamp = message.body.ChatMessage.timestamp;
        message.username = message.from.replace(this.currentRoom + '/', '');

        return message;
    }

    /**
     * @function stanzaToNotification
     * @description
     * @private
     * @param {Element} stanza
     * @returns {Notification}
     */
    private stanzaToNotification(stanza: Element): Notification {
        const notification = stanza.getElementsByTagName('notification');
        let message = new Notification();
        message.type = 'notification';
        message = this.fetchCommonMessageProperties(message, stanza);

        if (notification.length > 0) {
            message.body = JSON.parse(notification[0].textContent);
            message.state = message.body.notify.state;
            message.sessionId = message.body.notify.session;
        }

        return message;
    }

    /**
     * @function stanzaToSurveyNotification
     * @description
     * @private
     * @param {Element} stanza
     * @returns {SurveyNotification}
     */
    private stanzaToSurveyNotification(stanza: Element): SurveyNotification {
        const notification = stanza.getElementsByTagName('notification');
        let message = new SurveyNotification();
        message.type = 'surveyNotification';
        message = this.fetchCommonMessageProperties(message, stanza);

        message.body = JSON.parse(notification[0].textContent);
        message.status = message.body.notify.survey.status;
        message.sessionId = message.body.notify.survey.sessionID;
        message.questionID = message.body.notify.survey.questionID;
        message.id = message.body.notify.survey.id;

        return message;
    }

    /**
     * @function stanzaToMessage
     * @description
     * @private
     * @param {Element} stanza
     * @returns {MessageInterface}
     */
    private stanzaToMessage(stanza: Element): MessageInterface {
        let message = new Message();
        message = this.fetchCommonMessageProperties(message, stanza);
        return message;
    }

    /**
     * @function _getBrowserInfo
     * @description
     * @private
     * @returns {any}
     */
    private _getBrowserInfo(): any {
        const info: any = this._browserService.getAll();
        const platform = info.platform.type ? info.platform.type : '';
        const os = info.os.name ? (info.os.name + (info.os.version ? '-' + info.os.version : '')) : '';
        const browser = info.browser.name ? (info.browser.name + (info.browser.version ? '-' + info.browser.version : '')) : '';
        return {platform: platform, os: os, browser: browser};
    }

    /**
     * @function onXmppMsgReceive
     * @description
     * @private
     * @param {Element} stanza
     * @returns {boolean}
     */
    private onXmppMsgReceive(stanza: Element): void {
        // Avoiding delayed stanzas, except delayed Presence stanzas, used to detect dual login error
        if (stanza.getElementsByTagName('delay').length > 0 && stanza.tagName !== 'presence') {
            return;
        }
        const decodedMessage: MessageInterface = this.formatStanza(stanza);

        if (decodedMessage) {
            this.messageReceivedCallback(decodedMessage);
        }

        if (this.config.get('logXmppMessages')) {
            console.log(stanza);
        }
    }

    /**
     * @function fetchCommonMessageProperties
     * @description
     * @private
     * @param {any} message
     * @param {Element} stanza
     * @returns {any}
     */
    private fetchCommonMessageProperties(message: any, stanza: Element): any {
        const error = stanza.getElementsByTagName('error');
        const body = stanza.getElementsByTagName('body');

        message.from = stanza.getAttribute('from');
        message.to = stanza.getAttribute('to');

        if (stanza.getAttribute('type')) {
            message.type = stanza.getAttribute('type');
        }

        if (error.length > 0) {
            message.error = {message: error[0].textContent, code: error[0].getAttribute('code')};
        }

        if (body.length > 0) {
            message.body = JSON.parse(body[0].textContent);
        }

        return message;
    }

    /**
     * @function _stanzaToPresenterControl
     * @description
     * @private
     * @param {Element} stanza
     * @returns {PresenterControl}
     */
    private _stanzaToPresenterControl(stanza: Element): PresenterControl {
        let message = new PresenterControl();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'presenter-control';
        message.presenterID = message.body.ie.p;
        return message;
    }

    /**
     * @function stanzaToSessionStatus
     * @description
     * @private
     * @param {Element} stanza
     * @returns {SessionStatus}
     */
    private stanzaToSessionStatus(stanza: Element): SessionStatus {
        let message = new SessionStatus();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'session-state-status';
        message.isChatEnabled = message.body.SessionStatus.isChatEnabled;
        message.isDrawingEnabled = message.body.SessionStatus.isDrawingEnabled;
        message.isHandoverEnabled = message.body.SessionStatus.isHandoverEnabled;
        message.isConferenceEnabled = message.body.SessionStatus.isConferenceEnabled;
        message.currentSlide = message.body.SessionStatus.currentSlide;
        message.state = message.body.SessionStatus.state;
        message.relatedDocuments = message.body.SessionStatus.relatedDocuments;
        message.presentations = message.body.SessionStatus.presentations;
        message.customers = message.body.SessionStatus.customers;
        message.isHighlightingModeActive = message.body.SessionStatus.isHighlightingModeActive;

        if (message.body.SessionStatus.isInteractiveModeEnabled) {
            message.isInteractiveModeEnabled = message.body.SessionStatus.isInteractiveModeEnabled;
        }

        if (!message.body.SessionStatus.currentSlide.size ||
            !message.body.SessionStatus.currentSlide.size.width ||
            !message.body.SessionStatus.currentSlide.size.height) {
            message.body.SessionStatus.currentSlide.size = new ScreenSize();
        }

        return message;
    }

    /**
     * @function stanzaToRemoteRelatedDocument
     * @description
     * @private
     * @param {Element} stanza
     * @returns {RemoteRelatedFile}
     */
    private stanzaToRemoteRelatedDocument(stanza: Element): RemoteRelatedFile {
        let message = new RemoteRelatedFile();
        message = this.fetchCommonMessageProperties(message, stanza);

        if (message.body.RelatedDocumentOpenCommand) {
            message.type = message.body.RelatedDocumentOpenCommand.relatedDocument.type;
            message.networkURL = message.body.RelatedDocumentOpenCommand.relatedDocument.networkURL;
            message.name = message.body.RelatedDocumentOpenCommand.relatedDocument.name;
            message.action = RemoteRelatedFileAction.OPEN;
        }

        if (message.body.RelatedDocumentCloseCommand) {
            message.action = RemoteRelatedFileAction.CLOSE;
        }

        return message;
    }

    /**
     * @function stanzaToConferenceEnableCommand
     * @description
     * @private
     * @param {Element} stanza
     * @returns {ConferenceEnableCommand}
     */
    private stanzaToConferenceEnableCommand(stanza: Element): ConferenceEnableCommand {
        let message = new ConferenceEnableCommand();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'conference-enable-command';
        message.isConferenceEnabled = message.body.ConferenceChangeCommand.isEnabled;
        return message;
    }

    /**
     * @function stanzaToInteractiveModeCommand
     * @description
     * @private
     * @param {Element} stanza
     * @returns {InteractiveModeEnableCommand}
     */
    private stanzaToInteractiveModeCommand(stanza: Element): InteractiveModeEnableCommand {
        let message = new InteractiveModeEnableCommand();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'interactive-mode-enable-command';
        message.isEnabled = message.body.InteractiveModeCommand.isEnabled;
        return message;
    }

    /**
     * @function stanzaToHighlightingModeCommand
     * @description
     * @private
     * @param {Element} stanza
     * @returns {HighlightingModeCommand}
     */
    private stanzaToHighlightingModeCommand(stanza: Element): HighlightingModeCommand {
        let message = new HighlightingModeCommand();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'highlighting-mode-command';
        message.isEnabled = message.body.HighlightingModeCommand.isEnabled;
        return message;
    }

    /**
     * @function stanzaToDrawingModeCommand
     * @description
     * @private
     * @param {Element} stanza
     * @returns {DrawingModeCommand}
     */
    private stanzaToDrawingModeCommand(stanza: Element): DrawingModeCommand {
        let message = new DrawingModeCommand();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'drawing-mode-command';
        message.isEnabled = message.body.DrawingModeCommand.isEnabled;
        return message;
    }

}
