// Core modules
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

// Third-party modules
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';

// Internal interfaces
import {RouteReusableStrategy} from '@app/core/route-reusable-strategy';

// Internal modules
import {AbstractMessageProvider} from '@app/core/messaging-provider/abstract-message-provider';
import {AuthInterceptor} from '@app/core/authentication/authentication.interceptor';
import {AuthenticationGuard} from '@app/core/authentication/authentication.guard';

// Internal components
import {ShellComponent} from '@app/shell/shell.component';
import {HeaderComponent} from '@app/shell/header/header.component';

// Internal services
import {MessagingService} from '@app/core/messaging/messaging.service';
import {I18nService} from './i18n.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {XmppProviderService} from '@app/core/messaging-provider/xmpp-providers.service';
import {XmppRoomProvider} from '@app/core/messaging-provider/xmpp-room-provider.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        RouterModule,
        NgbModule,
    ],
    declarations: [
        ShellComponent,
        HeaderComponent
    ],
    providers: [
        MessagingService,
        XmppRoomProvider,
        AuthenticationService,
        AuthenticationGuard,
        I18nService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: AbstractMessageProvider,
            useClass: XmppProviderService
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        }
    ]
})
export class CoreModule {

    /**
     * @function constructor
     * @param {CoreModule} parentModule
     */
    constructor(
        @Optional() @SkipSelf() parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }

}
