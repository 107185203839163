// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {includes} from 'lodash';

// Internal services
import {Logger} from './logger.service';

// Global variables declaration
const languageKey = 'language';
const log = new Logger('I18nService');

/**
 * @function extract
 * @description Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @public
 * @param {string} s
 * @returns {string}
 */
export function extract(s: string) {
    return s;
}

@Injectable()
export class I18nService {

    /**
     * Data members
     */
    public defaultLocale: string;
    public availableLocales: string[];

    /**
     * @function constructor
     * @param {TranslateService} translateService
     */
    constructor(
        private translateService: TranslateService
    ) {}

    /**
     * @function language
     * @description
     * @public
     * @return {string}
     */
    get language(): string {
        return this.translateService.currentLang;
    }

    /**
     * @function isAsian
     * @getter
     * @description Check for Asian languages
     * @public
     * @returns {boolean}
     */
    get isAsian(): boolean {
        const reg = /ja-JP/gm;
        return reg.test(this.language);
    }

    /**
     * @function setDefaultLocale
     * @description
     * @param {string} language
     */
    set setDefaultLocale(language: string) {
        this.defaultLocale = language;
    }

    /**
     * @function setAvailableLocales
     * @description
     * @param {string[]} language
     */
    set setAvailableLocales(languages: string[]) {
        this.availableLocales = languages;
    }

    /**
     * @function language
     * @description Sets the current language.
     * Note: The current language is saved to the local storage.
     * If no parameter is specified, the language is loaded from local storage (if present).
     * @public
     * @param {string} language The IETF language code to set.
     */
    set language(language: string) {
        const localFromLocalStorage = localStorage.getItem(languageKey);
        const localFromBrowserLang = this.translateService.getBrowserCultureLang();

        let LocalStorageLocaleExists = includes(this.availableLocales, localFromLocalStorage);
        let BrowserLangLocaleExists = includes(this.availableLocales, localFromBrowserLang);

        language = language || localFromLocalStorage || localFromBrowserLang;

        // If no exact match found in localStorage, search for a similar language
        if (Boolean(localFromLocalStorage) && !LocalStorageLocaleExists) {
            language = localFromLocalStorage.split('-')[0];
            language = this.availableLocales.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
            LocalStorageLocaleExists = Boolean(language);
        }

        // If no exact match found with browser language, search for a similar language
        if (Boolean(localFromBrowserLang) && !LocalStorageLocaleExists && !BrowserLangLocaleExists) {
            language = localFromBrowserLang.split('-')[0];
            language = this.availableLocales.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
            BrowserLangLocaleExists = Boolean(language);
        }

        // If the locale is not supported at all, getting the custom default locale or the first locale fom the array
        if (!LocalStorageLocaleExists && !BrowserLangLocaleExists) {
            language = this.defaultLocale || this.availableLocales[0];
            console.warn(`Neither the locale '${localFromLocalStorage}' from the LocalStorage \
            (or a closer regional one) nor the locale '${localFromBrowserLang}' from the browser language \
            (or a closer regional one) matches with the available locales provided by the platform: \
            \n\n[${this.availableLocales.toString()}].
            \n\nSetting locale with the first list item: '${language}'`);
        }

        log.debug(`Locale set to ${language}`);
        this.translateService.use(language);
    }

    /**
     * @function init
     * @description Initializes i18n for the application.
     * Loads language from local storage if present, or sets default language.
     * @public
     */
    public init() {
        this.translateService.onLangChange
            .subscribe((event: LangChangeEvent) => {
                localStorage.setItem(languageKey, event.lang);
            });
    }

}
