// Core modules
import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';

// Third-party modules
import {TranslateService} from '@ngx-translate/core';

// Internal interfaces
import {AbstractMessageProvider} from '@app/core/messaging-provider/abstract-message-provider';

// Internal modules
import {SessionRoomStatus} from '@app/home/session/session-room-status';

// Internal models
import {Session, SessionAudioType} from '@app/shared/models/session';
import {Participant} from '@app/shared/models/participant';

// Internal services
import {HeaderService} from '@app/shell/header/header.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {I18nService} from '@app/core/i18n.service';
import {CustoService} from '@app/shared/service/custo.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {SdkService} from '@app/shared/service/sdk.service';
import {UtilService} from '@app/shared/service/util.service';
import {Logger} from '@app/core/logger.service';

// Global variables declaration
const logger = new Logger('Waiting Room');

@Component({
    selector: 'app-waiting-room',
    templateUrl: './waiting-room.component.html',
    styleUrls: ['./waiting-room.component.scss']
})
export class WaitingRoomComponent implements OnInit, AfterViewInit {

    /**
     * Data members
     */
    @Input() session: Session;
    @Input() speaker: Participant;
    @Input() isSpeakerOnline = false;
    @Input() roomStatus: SessionRoomStatus;
    @Input() sessionIsReady: boolean;
    @Output() onStartSession = new EventEmitter<void>();
    @Output() onExit = new EventEmitter<void>();
    @ViewChild('contentInfoContainer') contentInfoContainer: ElementRef;
    @ViewChild('stateContainer') stateContainer: ElementRef;
    @ViewChild('footer') footer: ElementRef;
    public isVertical: boolean;
    private _translations: string[] = [];
    public locale: string;
    public CUSTO: any = {};
    public isDesktop: boolean;
    public isSdkLoaded: boolean;

    /**
     * @function constructor
     * @param {CustoService} _custoService
     * @param {BrowserService} _browserService
     * @param {AuthenticationService} _authService
     * @param {AbstractMessageProvider} _messageProvider
     * @param {OpentokService} _opentokService
     * @param {HeaderService} _headerService
     * @param {TranslateService} _translateService
     * @param {I18nService} _i18nService
     * @param {SdkService} _sdkService
     * @param {UtilService} _utilService
     */
    constructor(
        private _custoService: CustoService,
        private _browserService: BrowserService,
        private _authService: AuthenticationService,
        private _messageProvider: AbstractMessageProvider,
        private _opentokService: OpentokService,
        private _headerService: HeaderService,
        private _translateService: TranslateService,
        private _i18nService: I18nService,
        private _sdkService: SdkService,
        private _utilService: UtilService
    ) {
        this._initTranslations();
        this.locale = this._i18nService.language;
        this.isVertical = this._windowOrientation();
    }

    /**
     * @function onResize
     * @description
     * @public
     * @returns {void}
     */
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.isVertical = this._windowOrientation();
        this._resizeContentInfoContainer();
        this._initView();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this._initCuto();
        this._initView();
        this._headerService.changeTitle(this._translations['sessions']);
        this._headerService.backButtonVisibility(false);
        this._sdkService.injectScript().then(() => {
          this.isSdkLoaded = true;
        });
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
        this._resizeContentInfoContainer();
    }

    /**
     * @function _initView
     * @description
     * @private
     * @returns {void}
     */
    private _initView() {
        if (this._isMobileView()) {
            this.isDesktop = false;
        } else if (this._isDesktopView()) {
            this.isDesktop = true;
        }
    }

    /**
     * @function _isMobileView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isMobileView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return this._browserService.isMobile() || (!this._browserService.isMobile() && windowWidth <= 823);
    }

    /**
     * @function _isDesktopView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isDesktopView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return !this._browserService.isMobile() && windowWidth > 823;
    }

    /**
     * @function _initCuto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _initCuto(locale: string = null): void {
      this.CUSTO['loginPageLogoUrl'] = this._custoService.getProp('loginPageLogoUrl', locale);
    }

    /**
     * @function startPresentation
     * @description
     * @public
     * @returns {void}
     */
    public startPresentation(): void {
        this.onStartSession.emit();
    }

    /**
     * @function canStartPresentation
     * @description
     * @public
     * @returns {boolean}
     */
    public canStartPresentation(): boolean {
      return this.roomStatus === SessionRoomStatus.Connected && this.isSpeakerOnline && this.isSdkLoaded && this.sessionIsReady;
    }

    /**
     * @function backToSessionList
     * @description
     * @public
     * @returns {void}
     */
    public backToSessionList(): void {
        this.onExit.emit();
    }

    /**
     * @function getConferenceStatus
     * @description
     * @public
     * @returns {string}
     */
    public getConferenceStatus(): string {
        return this._opentokService.conferenceStatus;
    }

    /**
     * @function getTranlatedConferenceStatus
     * @description
     * @public
     * @returns {boolean}
     */
    public getTranlatedConferenceStatus(): boolean {
        return this._translateService.instant( this._opentokService.conferenceStatus);
    }

    /**
     * @function isAllowedToStream
     * @description
     * @public
     * @returns {boolean}
     */
    public isAllowedToStream(): boolean {
        return this._opentokService.isPublishing();
    }

    /**
     * @function isStreamingAudio
     * @description
     * @public
     * @returns {boolean}
     */
    public isStreamingAudio(): boolean {
        return this._opentokService.isPublishingAudio();
    }

    /**
     * @function isStreamingVideo
     * @description
     * @public
     * @returns {boolean}
     */
    public isStreamingVideo(): boolean {
        return this._opentokService.isPublishingVideo();
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Sessions')
            .subscribe((trans: string) => this._translations['sessions'] = trans);
    }

    /**
     * @function _resizeContentInfoContainer
     * @description
     * @private
     * @returns {void}
     */
    private _resizeContentInfoContainer(): void {
        if (document.getElementById('header')) {
            this._goResizeContentInfoContainer();
        } else {
            // Handling to a timeout because #header and #footer are not accessible right now in ngAfterViewInit
            setTimeout(() => this._goResizeContentInfoContainer(), 300);
        }
    }

    /**
     * @function _goResizeContentInfoContainer
     * @description
     * @private
     */
    private _goResizeContentInfoContainer(): void {
        const header = document && document.getElementById('header');
        const headerHeight = header && header.offsetHeight || 0;
        const stateContainer = this.stateContainer && this.stateContainer.nativeElement;
        const statusContainerHeight = stateContainer && stateContainer.offsetHeight || 0;
        const footerContainer = this.footer && this.footer.nativeElement;
        const footerHeight = footerContainer && footerContainer.offsetHeight || 0;
        this.contentInfoContainer.nativeElement.style.height = (window.innerHeight - headerHeight - statusContainerHeight - footerHeight) + 'px';
    }

    /**
     * @function _windowOrientation
     * @description
     * @private
     * @returns {boolean}
     */
    private _windowOrientation(): boolean {
        return window.innerHeight > window.innerWidth;
    }

    /**
     * @function showConferenceStatus
     * @description
     * @public
     * @returns {boolean}
     */
    public showConferenceStatus(): boolean {
        return !!this.session && this.session.audioType === SessionAudioType.Isharing;
    }

    /**
     * @function getButtonStyle
     * @description
     * @public
     * @returns {string}
     */
    public getButtonStyle(): string {
        return this.canStartPresentation() ? 'btn-tertiary' : 'disabled';
    }

    /**
     * @function speakerFullName
     * @getter
     * @description Define user name format based on locale
     * @public
     * @returns {string}
     */
    public get speakerFullName(): string {
        if (!this.speaker) {
            return '';
        }

        return this._i18nService.isAsian ?
            `${this.speaker.last_name || ''} ${this.speaker.first_name || ''}` :
            `${this.speaker.first_name || ''} ${this.speaker.last_name || ''}`;
    }

}
