export interface RoomActivationFormField {
    var: string;
    value: any;
}

export interface RoomActivationForm {
    type: string;
    xmlns: string;
    fields: Array<RoomActivationFormField>;
}

export interface RoomActivationQuery {
    xmlns: string;
    x: RoomActivationForm;
}

const form = [
    {
        var: 'FORM_TYPE',
        value: 'http://jabber.org/protocol/muc#roomconfig'
    },
    {
        var: 'muc#roomconfig_publicroom',
        value: '1'
    },
    {
        var: 'muc#roomconfig_persistentroom',
        value: '1'
    },
    {
        var: 'public_list',
        value: '1'
    },
    {
        var: 'muc#roomconfig_passwordprotectedroom',
        value: '0'
    },

    {
        var: 'muc#roomconfig_membersonly',
        value: '0'
    },
    {
        var: 'muc#roomconfig_maxusers',
        value: '50'
    },
    {
        var: 'muc#roomconfig_whois',
        value: 'anyone'
    },
    {
        var: 'muc#roomconfig_moderatedroom',
        value: '0'
    },
    {
        var: 'members_by_default',
        value: '1'
    },
    {
        var: 'muc#roomconfig_changesubject',
        value: '0'
    },
    {
        var: 'allow_private_messages',
        value: '1'
    },
    {
        var: 'allow_query_users',
        value: '1'
    },
    {
        var: 'muc#roomconfig_allowinvites',
        value: '1'
    }
];

export class RoomActivation {
    public from: string;
    public to: string;
    public type: string;
    public id: string;
    query: RoomActivationQuery;

    constructor(from: string, to: string, type: string) {
        this.from = from;
        this.to = to;
        this.type = type;
        this.id = new Date().getTime().toString();
        this.query = {
            xmlns: 'http://jabber.org/protocol/muc#owner',
            x: {
                xmlns: 'jabber:x:data',
                type: 'submit',
                fields: form
            }
        };
    }
}
