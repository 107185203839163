// Core modules
import {Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

// Third-party modules
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

// Internal interfaces
import {MandatoryFileStatus, RemoteRelatedFile} from '@app/core/messaging/remote-related-file';
import {RelatedContentVisibility} from '@app/core/messaging/display-related-content';
import {ConferenceEnableCommand} from '@app/core/messaging/conference-enable-command';
import {SessionPlayPauseState} from '@app/core/messaging/session-play-pause-state';
import {HighlightingModeCommand} from '@app/core/messaging/highlighting-mode-command';
import {SessionStatus, statePlayPause} from '@app/core/messaging/session-state';
import {ChatMessage} from '@app/core/messaging/chat-message';
import {MessageInterface} from '@app/core/messaging/message';

// Internal models
import {Session, SessionAudioType} from '@app/shared/models/session';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from '@app/home/session/presentation/presentation-events.service';
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {FlashService} from '@app/shared/flash/flash.service';
import {UtilService} from '@app/shared/service/util.service';
import {CustoService} from '@app/shared/service/custo.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {SdkService} from '@app/shared/service/sdk.service';
import {SessionStatusService} from '@app/shared/service/sessionStatus.service';

@Component({
    selector: 'app-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss']
})
export class PresentationActionBarComponent implements OnInit, OnChanges, OnDestroy {

    /**
     * Data members
     */
    @Input() session: Session;
    @Input() actionBarsDisplayed: boolean;
    @ViewChild('mandatoryFileList') mandatoryFileList: ElementRef;
    public chatEnabled = false;
    public chatDisplayed = false;
    public newChatMessages = 0;
    public lockedScreen = false;
    public hasPresenterMode = false;
    public hasInteractiveMode = false;
    public askingForHand = false;
    public canStream = false;
    public askingForMic = false;
    public isDesktop: boolean;
    public mandatoryFile: RemoteRelatedFile[] = [];
    public hiddenMandatoryFiles = true;
    public isConferenceEnabled = false;
    public isSessionPaused: boolean;
    public drawingEnabled = false;
    public handoverEnabled = true;
    public isHighlightingModeActive: boolean;
    public isToolbarDisplayed: boolean;
    public isColorpickerDisplayed: boolean;
    public isExternalLinkOpened: boolean;
    private _actionBarsDisplayedTimer: any = null;
    private _relatedContentDisplay = false;
    private _noSleep: any;
    private _subscriptions: Subscription[] = [];
    private _isExternalUser: boolean;

    /**
     * @function constructor
     * @param {CustoService} _custo
     * @param {AuthenticationService} authService
     * @param {PresentationEventsService} eventsService
     * @param {MessagingService} messagingService
     * @param {SdkService} _sdkService
     * @param {FlashService} flashService
     * @param {Router} router
     * @param {OpentokService} conferenceService
     * @param {TranslateService} translateService
     * @param {UtilService} _utilService
     * @param {BrowserService} _browserService
     * @param {SessionStatusService} _sessionStatusService
     */
    constructor(
        private _custo: CustoService,
        private authService: AuthenticationService,
        private eventsService: PresentationEventsService,
        private messagingService: MessagingService,
        private _sdkService: SdkService,
        private flashService: FlashService,
        private router: Router,
        private conferenceService: OpentokService,
        private translateService: TranslateService,
        private _utilService: UtilService,
        private _browserService: BrowserService,
        private _sessionStatusService: SessionStatusService
    ) {
        this.canStream = this.conferenceService.isPublishing();

        this.authService.isExternalUser().then((bool: boolean) => {
            this._isExternalUser = bool;
        });

        this._subscriptions.push(this.messagingService.Messages
            .subscribe((message: MessageInterface) => {
                switch (message.constructor) {
                    case ChatMessage :
                        this.chatMessageReceived(<ChatMessage>message);
                        break;
                    case ConferenceEnableCommand:
                        const conferenceMessage = <ConferenceEnableCommand>message;
                        this.isConferenceEnabled = conferenceMessage.isConferenceEnabled;
                        break;
                    case SessionPlayPauseState:
                        const sessionPlayPauseState = <SessionPlayPauseState>message;
                        this.isSessionPaused = sessionPlayPauseState.state === statePlayPause.pause;
                        if (!this.isSessionPaused) {
                            this.isHighlightingModeActive = false;
                            this.isColorpickerDisplayed = false;
                            if (this.hasPresenterMode) {
                                this.giveControlBack();
                            }
                        }
                        break;
                    case HighlightingModeCommand:
                        const highlightingModeMessage = <HighlightingModeCommand>message;
                        this.isHighlightingModeActive = highlightingModeMessage.isEnabled;
                        break;
                    case SessionStatus:
                        const sessionStatusMessage = <SessionStatus>message;
                        this.isHighlightingModeActive = sessionStatusMessage.isHighlightingModeActive;
                        this.isSessionPaused = sessionStatusMessage.state === statePlayPause.pause;
                        break;
                }
            })
        );

        this.eventsService.actionRequestsReplay
            .subscribe((action: PresentationResponse) => {
                switch (action.event) {
                    case PresentationEvent.remoteRelatedContentAction:
                        this._setMandatoryFiles(<RemoteRelatedFile[]>action.data);
                        break;
                    case PresentationEvent.isChatEnabled:
                        this.chatEnabled = action.data;
                        break;
                    case PresentationEvent.isDrawingEnabled:
                        this.drawingEnabled = action.data;
                        break;
                    case PresentationEvent.isHandoverEnabled:
                        this.handoverEnabled = action.data;
                        break;
                    case PresentationEvent.isConferenceEnabled:
                        this.isConferenceEnabled = action.data;
                        break;
                }
            });

        this._subscriptions.push(this.eventsService.actionRequests
            .subscribe((action: PresentationResponse) => {
                switch (action.event) {
                    case PresentationEvent.HidePresentationActionBars:
                        this._hideActionBars();
                        break;

                    case PresentationEvent.ChatDisplayed:
                        this.chatDisplayed = true;
                        break;

                    case PresentationEvent.ChatClosed:
                        this.chatDisplayed = false;
                        break;

                    case PresentationEvent.StreamingDisabled:
                        this.canStream = false;
                        if (!this.lockedScreen) {
                            // enable nosleepjs when OpenTock stream is off
                            this.lockScreen();
                        }
                        break;

                    case PresentationEvent.StreamingAllowed:
                        this.canStream = true;
                        // disable nosleepjs when OpenTock stream (prevent performance issue)
                        this.unlockScreen();
                        break;

                    case PresentationEvent.relatedContentClick:
                        this._relatedContentDisplay = true;
                        break;

                    case PresentationEvent.askForHandNotification:
                        this.hasPresenterMode = action.data;
                        // Initializing variables when participant has just got the hand over
                        // Or when receiving a message from the presenter to get the hand over back
                        this.isHighlightingModeActive = false;
                        break;

                    case PresentationEvent.interactiveModeStatusNotification:
                        this.hasInteractiveMode = action.data;
                        // Deactivating highlighting mode if enabled after loosing interactive mode
                        if (!this.hasInteractiveMode && this.isHighlightingModeActive) {
                            this.isHighlightingModeActive = false;
                        }
                        break;
                    case PresentationEvent.externalLinkAction:
                        this.isExternalLinkOpened = true;
                        break;
                }
            })
        );
    }

    /**
     * @function onResize
     * @description
     * @public
     * @returns {void}
     */
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this._initView();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        if (this._browserService.isIE()) {
            this.mandatoryFileList.nativeElement.style.maxHeight = '200px';
        }
        this.isSessionPaused = this._sessionStatusService.sessionStatus && this._sessionStatusService.sessionStatus.state === statePlayPause.pause;
        this._noSleep = this._utilService.getNoSleepJs();
        this.lockScreen();
        this._initView();
    }

    /**
     * @function ngOnChanges
     * @param {any} changes
     */
    ngOnChanges(changes: any) {
        // Hiding toolbar panel when the bottom toolbar disappears
        if (changes.actionBarsDisplayed) {
            this.isToolbarDisplayed = false;
        }
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this.unlockScreen();
        this._subscriptions.forEach((subscription: Subscription) =>
            subscription.unsubscribe()
        );
    }

    /**
     * @function isAudioVideoSession
     * @description
     * @public
     * @return {boolean}
     */
    public isAudioVideoSession(): boolean {
        return this.session.audioType === SessionAudioType.Isharing;
    }

    /**
     * @function toWaitingRoom
     * @description
     * @public
     * @return {void}
     */
    public toWaitingRoom(): void {
        this.unlockScreen();
        this.eventsService.goToWaitingRoom();
    }

    /**
     * @function logout
     * @description
     * @public
     * @return {void}
     */
    public logout(): void {
        this.translateService.get('Are you sure you want to leave the presentation?')
            .subscribe((trans: string) => {
                if (confirm(trans)) {
                    this._utilService._doLogout();
                }
            });
    }

    /**
     * @function toggleConferencePanel
     * @description
     * @public
     * @returns {void}
     */
    public toggleChatPanel(): void {
        this.newChatMessages = 0;
        if (this.chatDisplayed) {
            this.eventsService.showChatPanel(false);
        } else {
            this.eventsService.showChatPanel(true);
        }
    }

    /**
     * @function chatMessageReceived
     * @description
     * @public
     * @param {ChatMessage} message
     * @returns {void}
     */
    public chatMessageReceived(message: ChatMessage): void {
        if (!this.chatDisplayed && message.username !== this.authService.credentials.username) {
            this.eventsService.notificationAction(message);
            this.newChatMessages++;
        }
    }

    /**
     * @function _enterFullScreen
     * @description
     * @public
     * @returns {void}
     */
    public toggleFullScreen(): void {
        if (!this.isFullScreen()) {
            this._enterFullScreen();
        } else {
            this._cancelFullScreen();
        }
    }

    /**
     * @function isFullScreen
     * @description
     * @private
     * @returns {boolean}
     */
    public isFullScreen(): boolean {
        const elem: any = document;
        return (elem.fullScreenElement && elem.fullScreenElement !== null) ||
            (elem.msFullscreenElement && elem.msFullscreenElement !== null) ||
            (elem.mozFullScreen || elem.webkitIsFullScreen);
    }

    /**
     * @function isIOS
     * @description
     * @public
     * @returns {boolean}
     */
    public isIOS(): boolean {
        return this._browserService.isIos();
    }

    /**
     * @function isExternalUser
     * @description
     * @public
     * @returns {boolean}
     */
    public isExternalUser(): boolean {
        return this._isExternalUser;
    }

    /**
     * @function toggleMandatoryFiles
     * @description
     * @public
     * @returns {void}
     */
    public toggleMandatoryFiles(): void {
        this.hiddenMandatoryFiles = !this.hiddenMandatoryFiles;
    }

    /**
     * @function openMandatoryFile
     * @description
     * @param {number} index
     * @public
     * @returns {void}
     */
    public openMandatoryFile(remoteRelate: RemoteRelatedFile): void {
        this.eventsService.remoteRelatedContentClickAction(remoteRelate);
        this.messagingService.mandatoryFileNotification(remoteRelate.name, MandatoryFileStatus.OPENED);
    }

    /**
     * @function requestStreamPublishing
     * @description
     * @public
     * @returns {void}
     */
    public requestStreamPublishing(): void {
        if (this.canStream || this.askingForMic) {
            return;
        }

        if (navigator.mediaDevices) {
            const vParam: boolean = this._browserService.isSafari() ? true : false;
            navigator.mediaDevices.getUserMedia({
                audio: true,
                video: vParam
            }).then(() => {
                this.conferenceService.deniedAVAccess = false;
                if (this.conferenceService.isConnLost()) {
                    this.translateService.get('network_connection_lost').subscribe(
                        ((txt: string) => {
                            this.flashService.error(txt, 99999999, true);
                        })
                    );
                } else {
                    this.askingForMic = true;
                    this.messagingService.requestAudioVideoStreaming();
                    this.translateService.get('Micro request sent').subscribe(
                        (text: string) => this.flashService.info(text)
                    );
                    setTimeout(() => {
                        this.askingForMic = false;
                    }, 5000);
                }

                }).catch(() => {
                this.conferenceService.deniedAVAccess = true;
                this.translateService.get('micro_camera_denied_notification').subscribe(
                    ((txt: string) => {
                        this.flashService.error(txt, 99999999, true);
                    })
                );
            });

        } else {
            this.translateService.get('not supported browser version for OpenTok').subscribe(
                (text: string) => this.flashService.info(text)
            );
        }
    }

    /**
     * @function stopStreamPublishing
     * @description
     * @public
     * @returns {void}
     */
    public stopStreamPublishing(): void {
        this.conferenceService.disable();
    }

    /**
     * @function isMicrophoneActivated
     * @description
     * @public
     * @returns {boolean}
     */
    public isMicrophoneActivated(): boolean {
        return this.conferenceService.isPublishingAudio();
    }

    /**
     * @function isCameraActivated
     * @description
     * @public
     * @returns {boolean}
     */
    public isCameraActivated(): boolean {
        return this.conferenceService.isPublishingVideo();
    }

    /**
     * @function muteUnmute
     * @description
     * @public
     * @returns {void}
     */
    public muteUnmute(): void {
        this.eventsService.toggleMicro();
    }

    /**
     * @function toggleVideo
     * @description
     * @public
     * @returns {void}
     */
    public toggleVideo(): void {
        this.eventsService.toggleVideo();
    }

    /**
     * @function lockScreen
     * @description
     * @public
     * @returns {void}
     */
    public lockScreen(): void {
        if (this.lockedScreen || this.canStream) {
            this.unlockScreen();
        } else {
            this.lockedScreen = true;
            this._noSleep.enable();
            // console.log('screen locked : ' + !this._noSleep.noSleepVideo.paused);
        }
    }

    /**
     * @function unlockScreen
     * @description
     * @public
     * @returns {void}
     */
    public unlockScreen(): void {
        this.lockedScreen = false;
        this._noSleep.disable();
        // console.log('screen locked : ' + !this._noSleep.noSleepVideo.paused);
    }

    /**
     * @function showDrawerAction
     * @description
     * @public
     * @returns {void}
     */
    public showDrawerAction(): void {
        this.eventsService.showDrawerAction();
    }

    /**
     * @function getControlRequest
     * @description
     * @public
     * @returns {void}
     */
    public getControlRequest(): void {
        this.askingForHand = true;
        this.messagingService.askForHand();
        this.translateService.get('Control request sent').subscribe(
            (text: string) => this.flashService.info(text)
        );
        setTimeout(() => {
            this.askingForHand = false;
        }, 10000);
    }

    /**
     * @function giveControlBack
     * @description
     * @public
     * @returns {void}
     */
    public giveControlBack(): void {
        this.hasPresenterMode = false;
        this.isHighlightingModeActive = false;
        this.isColorpickerDisplayed = false;
        this.messagingService.leaveHand();
        this.eventsService.isHandNotification(false);
    }

    /**
     * @function toggleToolbarPanel
     * @description
     * @public
     * @returns {void}
     */
    public toggleToolbarPanel(): void {
        this.isToolbarDisplayed = !this.isToolbarDisplayed;
    }

    /**
     * @function togglePencilMode
     * @description
     * @public
     * @returns {void}
     */
    public togglePencilMode(): void {
        this.isHighlightingModeActive = !this.isHighlightingModeActive;
        this.isColorpickerDisplayed = this.isHighlightingModeActive;
        this._sdkService.sdk.getPlayerJS().eventDispatcher({
            type: 'drawing-mode',
            isEnabled: this.isHighlightingModeActive,
            userID: this.authService.credentials.username
        });
    }

    /**
     * @function toggleColorpicker
     * @description
     * @public
     * @returns {void}
     */
    public toggleColorpicker(): void {
        this.isColorpickerDisplayed = !this.isColorpickerDisplayed;
        this._sdkService.sdk.getPlayerJS().eventDispatcher({
            type: 'colorpiker-action',
            show: this.isColorpickerDisplayed
        });
    }

    /**
     * @function getPencilClassnames
     * @description
     * @public
     * @returns {string}
     */
    public getPencilClassnames(): string {
        return this.isHighlightingModeActive ? 'active' : '';
    }

    /**
     * @function getColorpickerClassnames
     * @description
     * @public
     * @returns {string}
     */
    public getColorpickerClassnames(): string {
        let classnames = '';
        if (!this.isHighlightingModeActive) {
            classnames += 'disabled';
        } else if (this.isHighlightingModeActive && this.isColorpickerDisplayed) {
            classnames += 'active';
        }
        return classnames;
    }

    /**
     * @function showCallTestButton
     * @description Display the button to open pre-call test tool based on conditions
     * @public
     * @returns {boolean}
     */
    public showCallTestButton(): boolean {
        return this.isSessionPaused && this.notEdgeAndIE() && this.isConferenceEnabled;
    }

    /**
     * @function openCallTest
     * @description Open Pre-call test tool modal window
     * @public
     * @returns {void}
     */
    public openCallTest(): void {
        this.router.navigate(['sessions', 'call-test']);
    }

    /**
     * @function notEdgeAndIE
     * @description Check if user agent is not an old Microsoft Edge or Internet Explorer browsers
     * @public
     * @returns {boolean}
     */
    public notEdgeAndIE(): boolean {
        return !this._browserService.isEdge() && !this._browserService.isIE();
    }

    /**
     * @function closeExternalLink
     * @description Send message to close external link preview panel
     * @public
     */
    public closeExternalLink(): void {
        this.messagingService.closeLinkPreview();
        this.isExternalLinkOpened = false;
    }

    /**
     * @function _setMandatoryFiles
     * @description
     * @param {RemoteRelatedFile[]} relatedFiles
     * @private
     * @returns {void}
     */
    private _setMandatoryFiles(relatedFiles: RemoteRelatedFile[]): void {
        relatedFiles = _.filter(relatedFiles, (related: RemoteRelatedFile) => related.type === RelatedContentVisibility.MANDATORY);
        this.mandatoryFile = relatedFiles;
    }

    /**
     * @function _enterFullScreen
     * @description
     * @private
     * @returns {void}
     */
    private _enterFullScreen(): void {
        const elem: any = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen();
        }
    }

    /**
     * @function _cancelFullScreen
     * @description
     * @private
     * @returns {void}
     */
    private _cancelFullScreen(): void {
        const elem: any = document;
        if (elem.exitFullScreen) {
            return elem.exitFullScreen();
        } else if (elem.webkitCancelFullScreen) {
            return elem.webkitCancelFullScreen();
        } else if (elem.msExitFullscreen) {
            return elem.msExitFullscreen();
        } else if (elem.mozCancelFullScreen) {
            return elem.mozCancelFullScreen();
        }
    }

    /**
     * @function _hiddingActionBar
     * @description
     * @private
     * @returns {void}
     */
    private _hideActionBars(): void {
        this.actionBarsDisplayed = false;
        this._actionBarsDisplayedTimer = null;
    }

    /**
     * @function _initView
     * @description
     * @private
     * @returns {void}
     */
    private _initView() {
        if (this._isMobileView()) {
            this.isDesktop = false;
        } else if (this._isDesktopView()) {
            this.isDesktop = true;
        }
    }

    /**
     * @function _isMobileView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isMobileView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return this._browserService.isMobile() || (!this._browserService.isMobile() && windowWidth <= 823);
    }

    /**
     * @function _isDesktopView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isDesktopView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return !this._browserService.isMobile() && windowWidth > 823;
    }


}
