// Core modules
import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

// Third-party modules
import {Subscription} from 'rxjs';

// Internal interfaces
import {MessageInterface} from '@app/core/messaging/message';
import {RelatedContentVisibility} from '@app/core/messaging/display-related-content';

// Internal models
import {RemoteRelatedFile, RemoteRelatedFileAction} from '@app/core/messaging/remote-related-file';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from '@app/home/session/presentation/presentation-events.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {RelatedService} from '@app/shared/service/related.service';
import {UtilService} from '@app/shared/service/util.service';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-related-content',
    templateUrl: './related-content.component.html',
    styleUrls: ['./related-content.component.scss']
})
export class RelatedContentComponent implements OnDestroy {

    /**
     * Data members
     */
    @ViewChild('contentRelatedIFrame') contentRelatedIFrame: ElementRef;
    public currentRelatedContent: SafeResourceUrl = null;
    public closableRelatedContent = false;
    public relatedContentName = '';
    private subscriptions: Subscription[] = [];

    /**
     * @function constructor
     * @param {MessagingService} messagingService
     * @param {AuthenticationService} _authService
     * @param {DomSanitizer} _sanitizer
     * @param {PresentationEventsService} _eventService
     * @param {BrowserService} _browserService
     * @param {RelatedService} _relatedService
     * @param {UtilService} _utilService
     */
    constructor(
        private messagingService: MessagingService,
        private _authService: AuthenticationService,
        private _sanitizer: DomSanitizer,
        private _eventService: PresentationEventsService,
        private _browserService: BrowserService,
        private _relatedService: RelatedService,
        private _utilService: UtilService
    ) {
        this.subscriptions.push(
            this.messagingService.Messages
                .pipe(filter(message => message instanceof RemoteRelatedFile))
                .subscribe((message: MessageInterface) => {
                    const remoteFile = <RemoteRelatedFile>message;
                    if (remoteFile.action === RemoteRelatedFileAction.OPEN) {
                        // Open related
                        this.remoteRelatedContentClicked(remoteFile);
                    } else if (remoteFile.action === RemoteRelatedFileAction.CLOSE) {
                        // Close related
                        this.hideRelatedContentAction(remoteFile);
                    }
                })
        );
        this.subscriptions.push(this._eventService.actionRequests
            .subscribe(
                (action: PresentationResponse) => {
                    switch (action.event) {
                        case PresentationEvent.remoteRelatedContentClickAction:
                            this.remoteRelatedContentClicked(action.data);
                            break;
                        case PresentationEvent.askForHandNotification:
                            this._relatedService.isInPresenterMode = action.data;
                            break;
                    }
                }
            ));
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * @function remoteRelatedContentClicked
     * @description
     * @public
     * @param {RemoteRelatedFile} content
     * @returns {void}
     */
    public remoteRelatedContentClicked(content: RemoteRelatedFile): void {
        if (this._browserService.isAndroid() || (this._browserService.isIE() && !this._utilService.isAcrobatReaderInstalled())) {
            // Cannot open the modal window, then opening a new tab
            this._relatedService.openInNewTab(content.networkURL);
        } else if (this._browserService.isIphone() || this._browserService.isIpad()) {
            // Cannot open a new tab (because of browser security constraint),
            // Then displaying a notification with a button for the participant to open the shared content by itself
            if (content.type === RelatedContentVisibility.SHARED) {
                this._relatedService.sharedRelatedOpeningSubject.next(content.networkURL);
            } else {
                this._relatedService.openInNewTab(content.networkURL);
            }
        } else {
            // Can open a modal window
            this.currentRelatedContent = this._sanitizer.bypassSecurityTrustResourceUrl(content.networkURL);
            this.relatedContentName = content.name;
            if (content.type === RelatedContentVisibility.SHARED && !this._relatedService.isInPresenterMode) {
                this.closableRelatedContent = false;
            } else {
                this.closableRelatedContent = true;
            }
        }
    }

    /**
     * @function hideRelatedContentAction
     * @description
     * @public
     * @param {any} related
     * @returns {void}
     */
    public hideRelatedContentAction(related: any): void {
        if (this._relatedService.isInPresenterMode && this.currentRelatedContent) {
            this.messagingService.closeLinkPreview();
        }

        this._relatedService.closeTab();

        this.currentRelatedContent = null;
    }

}
