// Core modules
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, DecimalPipe} from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule} from '@angular/forms';

// Third-party modules
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CollapseModule, BsDropdownModule, AccordionModule, TabsModule} from 'ngx-bootstrap';
import {SimpleNotificationsModule} from 'angular2-notifications';

// Internal modules
import {HomeRoutingModule} from './home-routing.module';
import {SharedModule} from '@app/shared/shared.module';
import {CoreModule} from '@app/core/core.module';

// Internal components
import {HomeComponent} from './home.component';
import {SessionComponent} from './session/session.component';
import {WaitingRoomComponent} from '@app/home/session/waiting-room/waiting-room.component';
import {PresentationComponent} from '@app/home/session/presentation/presentation.component';
import {ConferenceComponent} from '@app/home/session/conference/conference.component';
import {ChatComponent} from '@app/home/session/chat/chat.component';
import {PresentationActionBarComponent} from './session/presentation/action-bar/action-bar.component';
import {RelatedContentComponent} from './session/presentation/related-content/related-content.component';
import {CommentBoxComponent} from './session/comment-box/comment-box.component';
import {PresenterControlComponent} from '@app/home/session/presentation/presenter-control/presenter-control.component';
import {DrawerComponent} from './session/presentation/drawer/drawer.component';
import {PreCallTestToolComponent} from '@app/home/pre-call-test-tool/pre-call-test-tool.component';

// Internal services
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';
import {OpentokNetworkTestService} from '@app/home/pre-call-test-tool/opentok-network-test.service';
import {PreCallTestToolGuard} from '@app/home/pre-call-test-tool/pre-call-test-tool.guard';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        DragDropModule,
        TranslateModule,
        CoreModule,
        FormsModule,
        SharedModule,
        HomeRoutingModule,
        NgbModule,
        CollapseModule,
        BsDropdownModule,
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        SimpleNotificationsModule.forRoot()
    ],
    declarations: [
        HomeComponent,
        WaitingRoomComponent,
        PresentationComponent,
        ChatComponent,
        SessionComponent,
        ConferenceComponent,
        PresentationActionBarComponent,
        RelatedContentComponent,
        CommentBoxComponent,
        PresenterControlComponent,
        DrawerComponent,
        PreCallTestToolComponent
    ],
    providers: [
        OpentokService,
        OpentokNetworkTestService,
        PresentationEventsService,
        DecimalPipe,
        PreCallTestToolGuard
    ]
})
export class HomeModule {}
