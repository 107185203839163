// Core modules
import {Component, HostListener, OnInit} from '@angular/core';

// Third-party modules
import {TranslateService} from '@ngx-translate/core';

// Internal services
import {I18nService} from '@app/core/i18n.service';
import {CustoService} from '@app/shared/service/custo.service';
import {BrowserService} from '@app/shared/service/browser.service';

@Component({
    selector: 'app-gdpr',
    templateUrl: './gdpr.component.html',
    styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent implements OnInit {

    /**
     * Data members
     */
    public CUSTO: any = {};
    public isDesktop: boolean;
    private _translations: string[] = [];

    /**
     * @function onResize
     * @description
     * @public
     * @returns {void}
     */
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this._resizeGdprContainer();
    }

    /**
     * @function constructor
     * @param {I18nService} _i18nService
     * @param {TranslateService} _translateService
     * @param {CustoService} _custoService
     * @param {BrowserService} _browserService
     */
    constructor(
        private _i18nService: I18nService,
        private _translateService: TranslateService,
        private _custoService: CustoService,
        private _browserService: BrowserService
    ) {
        if (this._browserService.isDesktop() && document.body.clientWidth > 823) {
            this.isDesktop = true;
        }
        this._initTranslations();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        setTimeout(() => {
            this._resizeGdprContainer();
        });
        this._initCusto();
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Client\'s privacy policy')
            .subscribe((trans: string) => this._translations['clients_privacy_policy'] = trans);
        this._translateService.get('IQVIA_gdpr')
            .subscribe((trans: string) => this._translations['IQVIA_gdpr'] = trans);
    }

    /**
     * @function _initCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _initCusto(locale: string = null): void {
        locale = !locale ? this._i18nService.language : locale;
        this.CUSTO['loginPageBackgroundColorMobile'] = this._custoService.getProp('loginPageBackgroundColorMobile', locale);
        this.CUSTO['loginPageBackgroundImageMobile'] = this._custoService.getProp('loginPageBackgroundImageMobile', locale);
        this.CUSTO['loginPageBackgroundPositionMobile'] = this._custoService.getProp('loginPageBackgroundPositionMobile', locale);
        this.CUSTO['loginPageBackgroundSizeMobile'] = this._custoService.getProp('loginPageBackgroundSizeMobile', locale);
        this.CUSTO['loginPageBackgroundColorDesktop'] = this._custoService.getProp('loginPageBackgroundColorDesktop', locale);
        this.CUSTO['loginPageBackgroundImageDesktop'] = this._custoService.getProp('loginPageBackgroundImageDesktop', locale);
        this.CUSTO['loginPageBackgroundPositionDesktop'] = this._custoService.getProp('loginPageBackgroundPositionDesktop', locale);
        this.CUSTO['loginPageBackgroundSizeDesktop'] = this._custoService.getProp('loginPageBackgroundSizeDesktop', locale);
        this.CUSTO['loginPageGdprText'] = this._custoService.getProp('loginPageGrpdText', locale);
        this.CUSTO['loginPageGdprTextIqvia'] = this._custoService.getProp('loginPageGdprTextIqvia', locale);
        this.CUSTO['loginPageGdprIqviaForClient'] = this._custoService.getProp('loginPageGdprIqviaForClient', locale);
    }



    /**
     * @function displayGdprClient
     * @description
     * @public
     * @returns {void}
     */
    public displayGdprClient(): void {
        const clientGdprMenu = document.getElementById('client-gdpr-menu');
        const iqviaGdprMenu = document.getElementById('iqvia-gdpr-menu');
        const clientGdprContent = document.getElementById('client-gdpr-content');
        const iqviaGdprContent = document.getElementById('iqvia-gdpr-content');

        clientGdprMenu.classList.add('active');
        iqviaGdprMenu.classList.remove('active');
        clientGdprContent.classList.add('active');
        iqviaGdprContent.classList.remove('active');
    }

    /**
     * @function _resizeGdprContainer
     * @description
     * @private
     * @returns {void}
     */
    private _resizeGdprContainer(): void {
        const gdprNav = document.getElementById('gdpr-nav');
        const gdprNavOffsetHeight = gdprNav && gdprNav.offsetHeight || 0;
        const gdprContentWrapper = document.getElementById('gdpr-content-wrapper');
        let windowHeight = 0;
        this._browserService.isIos() ? windowHeight = window.document.body.clientHeight : windowHeight = window.innerHeight;
        gdprContentWrapper.style.height = (windowHeight - gdprNavOffsetHeight) + 'px';
    }

    /**
     * @function getGdprText
     * @description
     * @public
     * @returns {string}
     */
    public getGdprText(): string {
        let gdpr: string;
        if (this.CUSTO['loginPageGdprIqviaForClient']) {
            // If loginPageGdprIqviaForClient exists as custo property and is set to true
            if (this.CUSTO['loginPageGdprTextIqvia']) {
                // Show custom IQVIA GDPR text if exists as custo property
                gdpr = this.CUSTO['loginPageGdprTextIqvia'];
            } else {
                // Or show default IQVIA GDPR text translation
                gdpr = this._translations['IQVIA_gdpr'];
            }
        } else if (!this.CUSTO['loginPageGdprIqviaForClient'] && this.CUSTO['loginPageGdprText']) {
            // Else, if loginPageGdprText exists as custo property
            // Show it
            gdpr = this.CUSTO['loginPageGdprText'];
        }
        return gdpr;
    }


    /**
     * @function getBackgroundStyle
     * @description
     * @public
     * @returns {any}
     */
    public getBackgroundStyle(): any {
        const style: any = {};
        if (this._isMobileView()) {
            // Custo for mobile
            if (this.CUSTO['loginPageBackgroundColorMobile']) {
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorMobile'];
            } else if (this.CUSTO['loginPageBackgroundColorDesktop']) {
                // Fallback with desktop data if no mobile background-color available
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorDesktop'];
            }

            if (this.CUSTO['loginPageBackgroundImageMobile']) {
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageMobile'] + ')';
                style.backgroundRepeat = 'no-repeat';
            } else if (this.CUSTO['loginPageBackgroundImageDesktop']) {
                // Fallback with desktop data if no mobile background-image available
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageDesktop'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }

            if (this.CUSTO['loginPageBackgroundPositionMobile']) {
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionMobile'];
            } else if (this.CUSTO['loginPageBackgroundPositionDesktop']) {
                // Fallback with desktop data if no mobile background-position available
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionDesktop'];
            }

            if (this.CUSTO['loginPageBackgroundSizeMobile']) {
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeMobile'];
            } else if (this.CUSTO['loginPageBackgroundSizeDesktop']) {
                // Fallback with desktop data if no mobile background-size available
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeDesktop'];
            }
        } else {
            // Custo for desktop
            if (this.CUSTO['loginPageBackgroundColorDesktop']) {
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorDesktop'];
            }
            if (this.CUSTO['loginPageBackgroundImageDesktop']) {
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageDesktop'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }
            if (this.CUSTO['loginPageBackgroundPositionDesktop']) {
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionDesktop'];
            }
            if (this.CUSTO['loginPageBackgroundSizeDesktop']) {
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeDesktop'];
            }
        }
        return style;
    }

    /**
     * @function _isMobileView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isMobileView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return this._browserService.isMobile() || (!this._browserService.isMobile() && windowWidth <= 823);
    }

}
