// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import NetworkTest from 'opentok-network-test-js';
import {map, take} from 'rxjs/operators';
import {ConnectivityTestResults} from 'opentok-network-test-js/dist/NetworkTest/testConnectivity';
import {UpdateCallback, UpdateCallbackStats} from 'opentok-network-test-js/dist/NetworkTest/types/callbacks';
import {QualityTestResults} from 'opentok-network-test-js/dist/NetworkTest/testQuality';
import {NetworkTestOptions} from 'opentok-network-test-js';

// Internal modules
import {AppConfig} from '@app/core/app.config';

// Internal services
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {SessionService} from '@app/shared/service/session.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {Logger} from '@app/core/logger.service';
import {Session} from '@app/shared/models/session';

// Global variables declaration
const logger = new Logger('OpentokNetworkTest');

@Injectable()
export class OpentokNetworkTestService {
    public readonly connectionOptions: NetworkTestOptions;
    private _otNetworkTest: NetworkTest;

    constructor(private config: AppConfig,
                private opentokService: OpentokService,
                private sessionService: SessionService,
                private authenticationService: AuthenticationService) {
        this.connectionOptions = {
            timeout: 30000,
            audioOnly: false
        };
    }

    /**
     * @function openTestConnection
     * @description Create Opentok test connection for provided session
     * @public
     * @param {Session} session - Session instance
     */
    public async openTestConnection(session: Session): Promise<void> {
        const sessionKey = SessionService.getSessionKey(session);
        const isExternalUser = await this.authenticationService.isExternalUser();

        return this.opentokService.initSession(sessionKey, !isExternalUser)
            .pipe(
                take(1),
                map((ot_session: OT.Session) => {
                    logger.info(ot_session);
                    return this.initTestSession();
                })
            )
            .toPromise();
    }

    /**
     * @function testConnectivity
     * @description Start testing the connectivity
     * @public
     * @returns {Promise<ConnectivityTestResults>}
     */
    public testConnectivity(): Promise<ConnectivityTestResults> {
        return this._otNetworkTest.testConnectivity();
    }

    /**
     * @function testQuality
     * @description Start testing the connectivity quality
     * @public
     * @param {UpdateCallback<UpdateCallbackStats>} progress - Callback function to get update stats
     * @returns {Promise<number>}
     */
    public testQuality(progress?: UpdateCallback<UpdateCallbackStats>): Promise<number> {
        return this._otNetworkTest.testQuality(progress)
            .then(result => this.summarizeQualityResults(result));
    }

    /**
     * @function initTestSession
     * @description Initialize test session
     * @private
     * @returns {void}
     */
    private initTestSession(): void {
        try {
            this._otNetworkTest = new NetworkTest(this.opentokService.getOT(), {
                apiKey: this.config.get('opentokApiKey'),
                sessionId: this.opentokService.getSessionId(),
                token: this.opentokService.getToken()
            }, this.connectionOptions);
        } catch (e) {
            logger.error(e);
        }
    }

    /**
     * @function summarizeQualityResults
     * @description Calculate MOS value for video and audio
     * @private
     * @param {QualityTestResults} result - Response object from Opentok test service
     * @returns {number} General MOS value
     */
    private summarizeQualityResults(result: QualityTestResults): number {
        /* For some reason opentok testing tool returns 'result.audio.supported: false'
        ** even if mos value is big enough to treat it like 'excellent connection'
        ** To avoid this bug, we check only if camera was enabled for testing
        *  and assume that audio is always supported
        */
        const isVideoAvailable = !this.connectionOptions.audioOnly;
        const sum = Object.keys(result)
            .filter(type => type === 'audio' || (type === 'video' && isVideoAvailable))
            .map(type => result[type].mos)
            .reduce((r, cur) => r + cur, 0);

        return isVideoAvailable ? sum / 2 : sum;
    }
}
