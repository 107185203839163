// Core modules
import {Pipe, PipeTransform} from '@angular/core';

export interface FilterItem {
    name: string;
    displayName?: string;
}

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    /**
     * @function transform
     * @description
     * @public
     * @param {any[]} items
     * @param {string} searchText
     * @return {any[]}
     */
    public transform(items: FilterItem[], searchText: string): any[] {

        function clearText(name: string) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = name;
            return tempDiv.innerText;
        }

        if (!items) {
            return [];
        }

        if (!searchText) {
            items.forEach((elt: any) => {
                elt.displayName = clearText(elt.name);
            });
            return items;
        }

        searchText = searchText.toLowerCase();

        return items.filter((it: any) => {
            const plainText = clearText(it.name);
            if (plainText.toLowerCase().includes(searchText)) {
                const regEx = new RegExp(searchText, 'gi');
                it.displayName = plainText.replace(regEx, match => `<span class="highlight">${match}</span>`);
                return it.displayName;
            }
        });
    }

}
