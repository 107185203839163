// Core modules
import {Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';

// Third-party modules
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

// Internal interfaces
import {MessageInterface} from '@app/core/messaging/message';

// Internal modules
import {ChatMessage} from '@app/core/messaging/chat-message';

// Internal services
import {PresentationEvent, PresentationEventsService, PresentationResponse} from '@app/home/session/presentation/presentation-events.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {SessionService} from '@app/shared/service/session.service';
import {AttendeesService} from '@app/shared/service/attendees.service';
import {UtilService} from '@app/shared/service/util.service';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnDestroy {

    /**
     * Data members
     */
    @Output() onClose = new EventEmitter();
    @ViewChild('chatScroll') private scrollContainer: ElementRef;
    public chatMessage: string;
    public chatMessages: ChatMessage[] = [];
    public username: string;
    public showChat = false;
    private subscriptions: Subscription[] = [];

    /**
     * @function constructor
     * @param {MessagingService} messagingService
     * @param {AuthenticationService} authService
     * @param {PresentationEventsService} _presentationActionsService
     * @param {SessionService} _sessionService
     * @param {AttendeesService} _attendeesService
     * @param {UtilService} _utilService
     */
    constructor(
        private messagingService: MessagingService,
        private authService: AuthenticationService,
        private _presentationActionsService: PresentationEventsService,
        private _sessionService: SessionService,
        private _attendeesService: AttendeesService,
        private _utilService: UtilService
    ) {
        this.subscriptions.push(
            this.messagingService.Messages
                .pipe(filter((message: MessageInterface) => message instanceof ChatMessage))
                .subscribe((message: ChatMessage) => {
                    message.user = _attendeesService.getAttendee(message.username);
                    this._utilService.setUserChatNickName(message).subscribe((data: string) => {
                        message.nickname = data;
                    });
                    this.chatMessages.push(message);
                    this.scrollToBottom();
                })
        );

        this.subscriptions.push(
            this._presentationActionsService.actionRequests.subscribe(
                (action: PresentationResponse) => {
                    switch (action.event) {
                        case PresentationEvent.ChatClose:
                            this.showChat = false;
                            this._presentationActionsService.notifyChatVisibiltyChanged(false);
                            break;
                        case PresentationEvent.ChatDisplay:
                            this.showChat = true;
                            this.scrollToBottom();
                            this._presentationActionsService.notifyChatVisibiltyChanged();
                            break;
                    }
                }
            )
        );

        this.username = this.authService.credentials.username;
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(
            (subscription: Subscription) => subscription.unsubscribe()
        );
    }

    /**
     * @function scrollToBottom
     * @description
     * @public
     * @returns {void}
     */
    public scrollToBottom(): void {
        if (!this.showChat) {
            return;
        }
        try {
            setTimeout(() => {
                this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
            }, 500);
        } catch (err) {
        }
    }

    /**
     * @function sendMessage
     * @description
     * @public
     * @returns {void}
     */
    public sendMessage(): void {
        if (!this.chatMessage) {
            return;
        }
        const message = {
            ...new ChatMessage(),
            type: 'groupchat',
            body: `{ "ChatMessage": { "content": "${this.chatMessage}", "senderJID": "${this.authService.credentials.username}" } }`
        };
        this.messagingService.sendMessage(message);
        this.chatMessage = null;
    }

    /**
     * @function closeChat
     * @description
     * @public
     * @returns {void}
     */
    public closeChat(): void {
        this.showChat = false;
        this._presentationActionsService.notifyChatVisibiltyChanged(false);
    }

    /**
     * @function isVertical
     * @description
     * @public
     * @returns {boolean}
     */
    public isVertical(): boolean {
        return window.innerHeight > window.innerWidth;
    }

    /**
     * @function chatTextTop
     * @description
     * @public
     * @returns {void}
     */
    public chatTextTop(): void {
        document.getElementsByClassName('chat-container')[0].classList.add('chat-text-top');
        // Moving back the window to the top (iOS moves window when keyboard is opened)
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
    }

    /**
     * @function chatTextNormal
     * @description
     * @public
     * @param {any} $event
     * @returns {void}
     */
    public chatTextNormal($event: any): void {
        document.getElementsByClassName('chat-container')[0].classList.remove('chat-text-top');
    }

}
