// Core modules
import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

// third-party modules
import {Subscription} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';

// Internal interfaces
import {ChatMessage} from '@app/core/messaging/chat-message';

// Internal models
import {Session, SessionAudioType} from '@app/shared/models/session';

// Internal services
import {SdkService} from '@app/shared/service/sdk.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {UtilService} from '@app/shared/service/util.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {Logger} from '@app/core/logger.service';
import {HeaderService} from '@app/shell/header/header.service';
import {PresentationEvent, PresentationEventsService, PresentationResponse} from '@app/home/session/presentation/presentation-events.service';
import {I18nService} from '@app/core/i18n.service';

// Global variables declaration
const logger = new Logger('Presentation');

@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit, OnDestroy, AfterViewInit {

    /**
     * Data members
     */
    @Input() session: Session;
    @ViewChild('contentIFrame') contentIFrame: ElementRef;
    public notificationsOptions: any = {
        position: ['bottom', 'right'],
        lastOnBottom: true,
        timeOut: 3000,
        showProgressBar: false,
        clickToClose: true,
        maxLength: 255, // Seems not to work
        maxStack: 3,
        theClass: ''
    };
    public actionBarsDisplayed: boolean;
    public drawerTabDisplayed: boolean;
    private _movingTimeout: number;
    private _movingTimeout2: number;
    private _subscriptions: Subscription[] = [];
    private sdk: any;

    /**
     * @function constructor
     * @param {PresentationEventsService} _presActionsService
     * @param {HeaderService} headerService
     * @param {NotificationsService} _notificationsService
     * @param {SdkService} _sdkService
     * @param {BrowserService} _browserService
     * @param {UtilService} _utilService
     * @param {AuthenticationService} _authService
     * @param {I18nService} _i18nService
     */
    constructor(
        private _presActionsService: PresentationEventsService,
        private headerService: HeaderService,
        private _notificationsService: NotificationsService,
        private _sdkService: SdkService,
        private _browserService: BrowserService,
        private _utilService: UtilService,
        private _authService: AuthenticationService,
        private _i18nService: I18nService
    ) {
        this._subscriptions.push(
            this._presActionsService.actionRequests.subscribe((action: PresentationResponse) => {
                if (action.event === PresentationEvent.notification) {
                    this._displayNotification(action.data);
                }
            })
        );
    }

    /**
     * @function onMousemove
     * @description
     * @public
     * @param {MouseEvent} event
     * @returns {void}
     */
    @HostListener('mousemove', ['$event'])
    onMousemove(event: MouseEvent): void {
        if (this._browserService.isDesktop()) {
            // Toggling top/bottom toolbars
            if (event.pageY <= 50 || event.pageY >= document.body.clientHeight - 50) {
                this._autoTogglingActionBar();
            }
            // Toggling drawer tab
            if (event.pageX <= 50) {
                this._autoTogglingDrawerTab();
            }
        }
    }

    /**
     * @function onTouchStart
     * @description
     * @public
     * @param {any} event
     * @returns {void}
     */
    @HostListener('touchstart', ['$event'])
    onTouchStart(event: any): void { // TouchEvent does not exist in Safari
        if (!this._browserService.isDesktop()) {
            if (event.target.id !== 'touch-panel') {
                // Toggling top/bottom toolbars
                this._autoTogglingActionBar();
                // Toggling drawer tab
                this._autoTogglingDrawerTab();
            }
        }
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this.headerService.changeVisibilty(false);
        this._detectRtlLanguages();
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this._subscriptions.forEach((subscription: Subscription) =>
            subscription.unsubscribe()
        );
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
        this._sdkService.injectScript().then(() => {
            this.sdk = this._sdkService.sdk;
            this.sdk.getPlayerJS().initModulesHTML();
        });
    }

    /**
     * @function _detectRtlLanguages
     * @description
     * @private
     * @returns {void}
     */
    private _detectRtlLanguages(): void {
        const rtlLangs: string[] = ['he-IL'];
        const html: any = document.getElementsByTagName('html')[0];
        if (rtlLangs.indexOf(this._i18nService.language) !== -1) {
            html.setAttribute('dir', 'rtl');
            html.classList.add('rtl');
        } else {
            html.removeAttribute('dir');
            html.classList.remove('rtl');
        }
    }

    /**
     * @function showConference
     * @description
     * @public
     * @returns {boolean}
     */
    showConference(): boolean {
        return (!!this.session && this.session.audioType === SessionAudioType.Isharing);
    }

    /**
     * @function _autoTogglingActionBar
     * @description
     * @private
     * @return {void}
     */
    private _autoTogglingActionBar(): void {
        this.actionBarsDisplayed = true;
        clearTimeout(this._movingTimeout);
        this._movingTimeout = setTimeout(() => {
            this.actionBarsDisplayed = false;
        }, 5000);
    }

    /**
     * @function _autoTogglingDrawerTab
     * @description
     * @private
     * @return {void}
     */
    private _autoTogglingDrawerTab(): void {
        this.drawerTabDisplayed = true;
        clearTimeout(this._movingTimeout2);
        this._movingTimeout2 = setTimeout(() => {
            this.drawerTabDisplayed = false;
        }, 5000);
    }

    /**
     * @function _displayNotification
     * @description
     * @private
     * @param {any} message
     * @return {void}
     */
    private _displayNotification(message: any): void {
        if (message instanceof ChatMessage) {
            this._displayChatNotification(message);
        }
    }

    /**
     * @function _displayNotification
     * @description
     * @private
     * @param {ChatMessage} message
     * @return {void}
     */
    private _displayChatNotification(message: ChatMessage): void {
        setTimeout(() => {
            this._utilService.setUserChatNickName(message).subscribe((user_name: string) => {
                this._notificationsService.bare(user_name, message.message, {theClass: 'chat-notif'});
            });
        }, 500);
    }

}
