// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import {ReplaySubject, Subject} from 'rxjs';

// Internal interfaces
import {TouchGesture} from '@app/core/messaging/touch-gesture';

export class PresentationResponse {

    /**
     * Data members
     */
    public event: PresentationEvent;
    public data: any;

    /**
     * @function constructor
     * @param type
     * @param data
     */
    constructor(
        type: PresentationEvent,
        data: any = null
    ) {
        this.event = type;
        this.data = data;
    }
}

export enum PresentationEvent {
    ToWaitingRoom = 'to_w_room',
    ChatDisplay = 'chat_display',
    ChatClose = 'chat_close',
    ChatDisplayed = 'chat_displayed',
    ChatClosed = 'chat_closed',
    HidePresentationActionBars = 'hide_action_bars',
    ClearDrawedShapes = 'clear_drawed_shapes',
    LaunchEventOnContent = 'launch_event_on_content',
    ToggleCamera = 'toggle_camera',
    ToggleMicro = 'toggle_micro',
    StreamingAllowed = 'streaming_allowed',
    StreamingDisabled = 'streaming_disabled',
    relatedContentClick = 'related_content_click',
    remoteRelatedContentAction = 'remote_related_content_action',
    remoteRelatedContentClickAction = 'remote_related_content_click_action',
    askForHandNotification = 'ask_hand_notification',
    interactiveModeStatusNotification = 'interactive_mode_status_notification',
    collapseDrawer = 'collapse_drawer',
    updateDrawer = 'update_drawer',
    notification = 'notification',
    isChatEnabled = 'is_chat_enabled',
    isDrawingEnabled = 'is_drawing_enabled',
    isHandoverEnabled = 'is_handover_enabled',
    isConferenceEnabled = 'is_conference_enabled',
    ConferenceCanConnect = 'conference-can-connect',
    sessionDrawerInfo = 'session-drawer-info',
    externalLinkAction = 'external_link'
}

@Injectable()
export class PresentationEventsService {

    /**
     * Data members
     */
    private _actionRequests: Subject<PresentationResponse> = new Subject<PresentationResponse>();
    private _actionRequestsReplay: ReplaySubject<PresentationResponse> = new ReplaySubject<PresentationResponse>();

    /**
     * @function constructor
     */
    constructor() {}

    /**
     * @function actionRequests
     * @description
     * @public
     * @returns {Subject<PresentationResponse>}
     */
    get actionRequests(): Subject<PresentationResponse> {
        return this._actionRequests;
    }

    /**
     * @function actionRequestsReplay
     * @description
     * @public
     * @returns {ReplaySubject<PresentationResponse>}
     */
    get actionRequestsReplay(): ReplaySubject<PresentationResponse> {
        return this._actionRequestsReplay;
    }

    /**
     * @function goToWaitingRoom
     * @description
     * @public
     * @returns {void}
     */
    public goToWaitingRoom(): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.ToWaitingRoom));
    }

    /**
     * @function showChatPanel
     * @description
     * @public
     * @param {boolean} show
     * @returns {void}
     */
    public showChatPanel(show: boolean = true): void {
        if (show) {
            this._actionRequests.next(new PresentationResponse(PresentationEvent.ChatDisplay));
        } else {
            this._actionRequests.next(new PresentationResponse(PresentationEvent.ChatClose));
        }
    }

    /**
     * @function notifyChatVisibiltyChanged
     * @description
     * @public
     * @param {boolean} displayed
     * @returns {void}
     */
    public notifyChatVisibiltyChanged(displayed: boolean = true) {
        if (displayed) {
            this._actionRequests.next(new PresentationResponse(PresentationEvent.ChatDisplayed));
        } else {
            this._actionRequests.next(new PresentationResponse(PresentationEvent.ChatClosed));
        }
    }

    /**
     * @function clearDrawedShapes
     * @description
     * @public
     * @returns {void}
     */
    public clearDrawedShapes() {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.ClearDrawedShapes));
    }

    /**
     * @function launchEvent
     * @description
     * @public
     * @param {TouchGesture} touch
     * @returns {void}
     */
    public launchEvent(touch: TouchGesture): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.LaunchEventOnContent, touch));
    }

    /**
     * @function toggleVideo
     * @description
     * @public
     * @returns {void}
     */
    public toggleVideo(): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.ToggleCamera));
    }

    /**
     * @function toggleMicro
     * @description
     * @public
     * @returns {void}
     */
    public toggleMicro(): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.ToggleMicro));
    }

    /**
     * @function streamingAllowedNotify
     * @description
     * @public
     * @returns {void}
     */
    public streamingAllowedNotify(): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.StreamingAllowed));
    }

    /**
     * @function streamingDisabledNotify
     * @description
     * @public
     * @returns {void}
     */
    public streamingDisabledNotify(): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.StreamingDisabled));
    }

    /**
     * @function remoteRelatedContentAction
     * @description
     * @public
     * @param {any} related
     * @returns {void}
     */
    public remoteRelatedContentAction(related: any): void {
        this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.remoteRelatedContentAction, related));
    }

    /**
     * @function remoteRelatedContentClickAction
     * @description
     * @public
     * @param {any} related
     * @returns {void}
     */
    public remoteRelatedContentClickAction(related: any): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.remoteRelatedContentClickAction, related));
    }

    /**
     * @function isHandNotification
     * @description
     * @public
     * @param {boolean} handIsActive
     * @returns {void}
     */
    public isHandNotification(handIsActive: boolean): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.askForHandNotification, handIsActive));
    }

    /**
     * @function interactiveModeStatusNotification
     * @description
     * @public
     * @param {boolean} isEnabled
     * @returns {void}
     */
    public interactiveModeStatusNotification(isEnabled: boolean): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.interactiveModeStatusNotification, isEnabled));
    }

    /**
     * @function showDrawerAction
     * @description
     * @public
     * @returns {void}
     */
    public showDrawerAction(): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.collapseDrawer));
    }

    /**
     * @function updateDrawerAction
     * @description
     * @public
     * @param {any} message
     * @returns {void}
     */
    public updateDrawerAction(message: any): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.updateDrawer, message));
    }

    /**
     * @function notificationAction
     * @description
     * @public
     * @param {any} message
     * @returns {void}
     */
    public notificationAction(message: any): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.notification, message));
    }

    /**
     * @function isChatEnabled
     * @description
     * @public
     * @param {boolean} message
     * @returns {void}
     */
    public isChatEnabled(message: boolean): void {
        this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.isChatEnabled, message));
    }

    /**
     * @function isDrawingEnabled
     * @description
     * @public
     * @param {boolean} message
     * @returns {void}
     */
    public isDrawingEnabled(message: boolean): void {
        this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.isDrawingEnabled, message));
    }

    /**
     * @function isHandoverEnabled
     * @description
     * @public
     * @param {boolean} message
     * @returns {void}
     */
    public isHandoverEnabled(message: boolean): void {
        this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.isHandoverEnabled, message));
    }

    /**
     * @function isConferenceEnabled
     * @description
     * @public
     * @param {boolean} message
     * @returns {void}
     */
    public isConferenceEnabled(message: boolean): void {
        this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.isConferenceEnabled, message));
    }

    /**
     * @function conferenceCanConnect
     * @description
     * @public
     * @param {boolean} message
     * @returns {void}
     */
    public conferenceCanConnect(message: boolean): void {
        this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.ConferenceCanConnect, message));
    }

    /**
     * @function sessiondrawerInfo
     * @description
     * @public
     * @param {any} drawerInfo
     * @returns {void}
     */
    public sessiondrawerInfo(drawerInfo: any): void {
        this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.sessionDrawerInfo, drawerInfo));
    }

    /**
     * @function showExternalLinkCloseAction
     * @description
     * @public
     * @returns {void}
     */
    public showExternalLinkCloseAction(): void {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.externalLinkAction));
    }

}
