import { Injectable } from '@angular/core';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {RoomActivation} from '@app/core/messaging/room-activation';

declare var $iq: any;

@Injectable()
export class XmppRoomProvider {

    constructor(private authService: AuthenticationService) { }

    /**
     * @function checkForNewRoom
     * @description
     * @public
     * @param {HTMLCollectionOf<Element>} status - XMPP stanza message
     * @returns {boolean}
     */
    public checkForNewRoom(status: HTMLCollectionOf<Element>): boolean {
        const arr = Array.from(status);
        return arr.length > 0 && arr.every(s => {
            const code = s.getAttribute('code');
            return  code === '201' || code === '110';
        });
    }

    /**
     * @function stanzaToUnlockRoom
     * @description
     * @public
     * @returns {void}
     */
    public stanzaToUnlockRoom(connection: any, currentRoom: any): void {
        const message = new RoomActivation(this.authService.credentials.connection_id, currentRoom, 'set');
        const field = document.createElement('FIELD');
        const value = document.createElement('VALUE');
        field.appendChild(value);

        let config = $iq({
            from: message.from,
            to: message.to,
            id: message.id,
            type: message.type
        }).c('query', {
            xmlns: message.query.xmlns
        }).c('x', {
            xmlns: message.query.x.xmlns,
            type: message.query.x.type
        });

        message.query.x.fields.forEach((pair) => {
            setFormValue(pair.var, pair.value);
        });

        if (connection) {
            const stanza = config.tree();
            connection.sendIQ(stanza);
        }

        function setFormValue(fValue: string, vValue: string): void {
            field.setAttribute('var', fValue);
            value.innerText = vValue;
            config = config.cnode(field).up();
        }
    }
}
