// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import * as _ from 'lodash';

// Internal model
import {User} from '@app/shared/models/user';

@Injectable()
export class AttendeesService {

    private _listOfAttendees: Array<User> = [];

    /**
     * @function constructor
     */
    constructor() {
    }

    /**
     * @function setAttendees
     * @description
     * @public
     * @param {User} user
     * @returns {Array<User>}
     */
    setAttendees(user: User): Array<User> {
        this._listOfAttendees.push(user);
        return this._listOfAttendees;
    }

    /**
     * @function getAttendee
     * @description
     * @public
     * @param {string} username
     * @returns {User}
     */
    getAttendee(username: string): User {
        return _.find(this._listOfAttendees, (attendee: User) => attendee.uid === username);
    }

    /**
     * @function deleteAttendee
     * @description
     * @public
     * @param {string} username
     * @returns {void}
     */
    deleteAttendee(username: string): void {
        this._listOfAttendees = _.reject(this._listOfAttendees, (attendee: User) => attendee.uid === username);
    }

}
