export interface Config {

    env: string;
    production: boolean;
    serverUrl: string;
    serverApiUrl: string;
    serverApiOTUrl: string;
    authUrl: string;
    authClientId: string;
    sdkJsPlayer: string;
    webSocketUrl: string;
    opentokApiKey: number;
    logXmppMessages?: boolean;

}

/**
 * Type Guard
 */
export function isConfigValid(config: any): boolean {
    return config.env !== undefined
        && config.production !== undefined
        && config.serverUrl !== undefined
        && config.serverApiUrl !== undefined
        && config.serverApiOTUrl !== undefined
        && config.authUrl !== undefined
        && config.authClientId !== undefined
        && config.sdkJsPlayer !== undefined
        && config.webSocketUrl !== undefined
        && config.opentokApiKey !== undefined;
}
