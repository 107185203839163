// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import {fromEvent} from 'rxjs';
import {Logger} from '@app/core/logger.service';

// Internal modules
import {AppConfig} from '@app/core/app.config';

// Global variable declaration
declare var SDK: any;
const logger = new Logger('SDK');

export enum SDK_EVENTS {
    TOUCH_EVENT = 'touch-gesture',
    VIDEO_EVENT = 'video-action',
    NOTIFICATION = 'notification',
    LOAD_SLIDE = 'load-slide',
    SLIDE_LOADED_EVENT = 'slide-loaded-event',
    DRAWING_EVENT_NOTIFICATION = 'drawing-event_notification',
    DRAWING_ERASE_NOTIFICATION = 'drawing-erase-notification'
}


@Injectable()
export class SdkService {

    /**
     * Data members
     */
    private isLoading = false;
    private _sdkJsPlayerScript: any = null;
    private _sdk: any;

    /**
     * @function constructor
     * @param {AppConfig} config
     */
    constructor(
        private config: AppConfig
    ) {}

    /**
     * @function sdk
     * @description
     * @public
     * @returns {any}
     */
    public get sdk(): any {
        return this._sdk;
    }

    /**
     * @function injectScript
     * @description
     * @public
     * @returns {any}
     */
    public injectScript(): any {
        return new Promise((resolve, reject) => {
            if (!this._sdk) {
                // One injection only
                if (!this.isLoading) {
                    this.isLoading = true;
                    const hash = new Date().getTime();
                    this._sdkJsPlayerScript = document.createElement('script');
                    this._sdkJsPlayerScript.type = 'text/javascript';
                    this._sdkJsPlayerScript.src = this.config.get('sdkJsPlayer') + '?' + hash;
                    document.getElementsByTagName('head')[0].appendChild(this._sdkJsPlayerScript);
                }
                fromEvent(this._sdkJsPlayerScript, 'load').subscribe((event: Event) => {
                    this.initSDK();
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    /**
     * @function initSDK
     * @description
     * @public
     * @returns {void}
     */
    public initSDK(): void {
        // Initializing the SDK JS Module at every load of the presentation component.
        // Prevents potential SDK's subjects subscriptions lost
        if (!this._sdk) {
            this._sdk = new SDK.Bootstrap('player-js-container');
            logger.info('Script initialized');
        }
    }


}
